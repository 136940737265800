<template>
  <div class="customDateRangeSelector">
    <label for="monthSelect" class="form-label"> Select Dates Range </label>
    <div class="dateRanges">
      <div>
        <date-range-picker
          ref="picker1"
          :opens="selectorDirections"
          :locale-data="{ firstDay: 1, format: 'yyyy-dd-mm' }"
          :showWeekNumbers="false"
          :showDropdowns="true"
          :autoApply="true"
          v-model="dateRange1"
          @update="updateRangeValues('fetchNewDataBasisOnRange1', $event)"
          :dateFormat="dateFormat"
          control-container-class="picker-controller small"
          :ranges="dateRanges"
          :auto-apply="false"
          :readonly="false"
          :max-date="calculateMaxDate()"
          class="customDateRange"
        >
          <template v-slot:input="picker">
            <feather-icon
              icon="CalendarIcon"
              size="16"
              class="mr-1"
            ></feather-icon>
            <span class="ranges">
              {{ formatDateDisplay(picker.startDate) }} -
              {{ formatDateDisplay(picker.endDate) }}
            </span>
          </template>
        </date-range-picker>
      </div>
      <div>
        <date-range-picker
          ref="picker2"
          :opens="selectorDirections"
          :locale-data="{ firstDay: 1, format: 'yyyy-dd-mm' }"
          :showWeekNumbers="false"
          :showDropdowns="true"
          :autoApply="true"
          v-model="dateRange2"
          @update="updateRangeValues('fetchNewDataBasisOnRange2', $event)"
          :dateFormat="dateFormat"
          control-container-class="picker-controller small"
          :ranges="dateRanges"
          :auto-apply="false"
          :readonly="false"
          :min-date="calculateMinDate()"
          :max-date="calculateMaxDate()"
          class="customDateRange"
        >
          <template v-slot:input="picker">
            <feather-icon
              icon="CalendarIcon"
              size="16"
              class="mr-1"
            ></feather-icon>
            <span class="ranges">
              {{ formatDateDisplay(picker.startDate) }} -
              {{ formatDateDisplay(picker.endDate) }}
            </span>
          </template>
        </date-range-picker>
      </div>
    </div>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BButton, BLink, BCardBody } from "bootstrap-vue";
import vSelect from "vue-select";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BLink,
    vSelect,
    BCardBody,
    DateRangePicker,
  },
  data() {
    return {
      minDate: new Date(),
      previousDateRange2: null,
      showModal: false,
      openScheduleModal: false,
      isTriggerModal: false,
      selected: {
        label: "Last 7 days",
        code: "LAST_7_DAYS",
      },
      opens: true,
      dateRange1: {
        endDate: moment()
          .subtract(14, "days")
          .format("YYYY-MM-DD"),
        startDate: moment()
          .subtract(21, "days")
          .format("YYYY-MM-DD"),
      },
      dateRange2: {
        endDate: moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD"),
        startDate: moment()
          .subtract(14, "days")
          .format("YYYY-MM-DD"),
      },
    };
  },
  props: {
    selectorDirections: {
      type: String,
    },
  },
  computed: {
    dateRanges() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);

      let yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);

      let last7thDay = new Date();
      last7thDay.setDate(today.getDate() - 7);
      last7thDay.setHours(0, 0, 0, 0);

      let last14thDay = new Date();
      last14thDay.setDate(today.getDate() - 14);
      last14thDay.setHours(0, 0, 0, 0);

      let last30thDay = new Date();
      last30thDay.setDate(today.getDate() - 30);
      last30thDay.setHours(0, 0, 0, 0);

      var thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
      var thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);

      let ranges = {
        Today: [today, today],
        Yesterday: [yesterday, yesterday],
        "Last 7 Days": [last7thDay, yesterday],
        "Last 14 Days": [last14thDay, yesterday],
        "Last 30 Days": [last30thDay, yesterday],
        "Last Month": [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0),
        ],
        "This Month": [thisMonthStart, thisMonthEnd],
        "This Year": [new Date(today.getFullYear(), 0, 1), new Date(today)],
      };

      return ranges;
    },
  },
  filters: {
    date(val) {
      return val ? moment(val).format("DD-MM-YYYY") : "";
    },
  },
  methods: {
    calculateMinDate() {
      const minDate = new Date(this.dateRange1.endDate);
      minDate.setDate(minDate.getDate() + 1);

      return minDate;
    },
    calculateMaxDate() {
      const maxDate = new Date();
      maxDate.setHours(23, 59, 59, 999);
      return maxDate;
    },
    dateChanged(val) {
      this.$emit("fetchNewData", val.code);
    },
    formatDateDisplay(date) {
      return moment(date).format(this.dateFormat);
    },
    // updateRange1Values(values) {
    //   let startDate = moment(values.startDate).format("YYYY-MM-DD");
    //   let endDate = moment(values.endDate).format("YYYY-MM-DD");
    //   this.$emit("fetchNewDataBasisOnRange1", startDate, endDate);
    // },
    // updateRange2Values(values) {
    //   let startDate = moment(values.startDate).format("YYYY-MM-DD");
    //   let endDate = moment(values.endDate).format("YYYY-MM-DD");
    //   this.$emit("fetchNewDataBasisOnRange2", startDate, endDate);
    // },
    updateRangeValues(eventName, values) {
      if (values && values.startDate && values.endDate) {
        let startDate = moment(values.startDate).format("YYYY-MM-DD");
        let endDate = moment(values.endDate).format("YYYY-MM-DD");
        this.$emit(eventName, startDate, endDate);
      } else {
        console.error("Invalid values:", values);
      }
    },
    dateFormat(classes, date) {
      let yesterday = new Date();

      let d1 = moment(date).format("YYYY-MM-DD");
      let d2 = moment(yesterday.setDate(yesterday.getDate() - 1)).format(
        "YYYY-MM-DD"
      );

      // if (!classes.disabled) {
      //   classes.disabled = d1 === d2;
      // }
      return classes;
    },
    formatDateDisplay(date) {
      return moment(date).format("YYYY-MM-DD");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.customDateRange{
  .daterangepicker .calendar-table td, .daterangepicker .calendar-table th {
    white-space: nowrap;
    vertical-align: middle;
    min-width: 44px !important;
    width: 41px;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
}
  .daterangepicker .monthselect {
  margin-right: 0.2rem;
  margin-left: -1.2rem;
 }
 .daterangepicker.show-ranges.show-weeknumbers[data-v-1ebd09d2], .daterangepicker.show-ranges[data-v-1ebd09d2] {
        min-width: 840px !important;
    }
  .daterangepicker.ltr .drp-calendar.left {
        clear: left !important;
        margin-right: 15% !important;
  }
}

.vue-daterange-picker {
  // width: 125%;

  svg {
    color: #4d21ff;
  }
}
.customDateRangeSelector {
  width: 35rem;
}
.reportrange-text {
  display: flex;
  align-items: center;
}
.dateRanges {
  display: flex;
  gap: 10px;
  margin-top: -5px;
}

.picker-controller {
  background-color: #f5f6fa;
  padding: 8px;
  margin-top: 6px;
}

.app-title-date-rages {
  font-size: 12px;
  font-weight: 600;
  color: #200e6b;
}

.warning {
  color: red;
  font-size: 10px;
}
@media only screen and (min-width: 767px) and (max-width: 1281px) {
  .customDateRangeSelector {
    width: 27rem !important;
  }
  .dateRanges {
    display: flex;
    justify-content: space-evenly;
    gap: 4rem;
    margin-top: -5px;
  }
  .ranges {
    font-size: 12px;
  }
}
</style>
