<template>
  <div>
    <b-overlay
      :show="isReviewClientFetched"
      rounded="sm"
      class="dashboard-overlay-reviews"
    >
      <div style="height: auto; background-color: #F5F7F8 !important;">
        <b-card
          style="margin-left: -7px; margin-right: -7px; min-height: 70vh;"
        >
          <div class="filter-container-for-reviews">
            <b-row class="filter-styling">
              <label
                for="monthSelect"
                class="form-label"
                style="margin-top: 5px;"
                >Select Client:</label
              >
              <b-col cols="3">
                <div>
                  <div>
                    <select
                      v-model="selectedReviewsClient"
                      class="gmb-review-select "
                      @change="getReviewsData"
                    >
                      <option value="">Select Client</option>
                      <option
                        v-for="onetype in fetchClientsList"
                        :value="onetype._id"
                        class="form-control "
                        id="lg_type"
                        name="lg_type"
                        v-bind:key="onetype._id"
                      >
                        {{ onetype.name }}
                      </option>
                    </select>
                  </div>
                </div></b-col
              >
            </b-row>
          </div>
          <b-overlay
            v-if="isReviewClientFetched === false "
            :show="isLocationsFetched"
            rounded="sm"
            class="dashboard-overlay-reviews"
          >
            <b-row v-if="isLocationsFetched === false"
              class="p-1 mainContainerForReview"
              style=" background-color: #f5f7f8"
            >
              <b-col cols="6" class="reviewLeft">
                <div>
                  <div>
                    <div class="table-content-row ml-1 mainReviewLeftContainer">
                      <div
                        style="margin-top: 10px; margin-left: 10px;
                         margin-bottom: 10px; width: 99%; display: flex; justify-content: space-between;"
                      >
                        <div
                          style="color: rgb(32, 14, 107); font-weight: bold;"
                        >
                          Stores
                        </div>
                        <div> 
                            <span @click="toggleSortOrder" style="cursor: pointer;"> 
                              <b-icon v-if="this.sortDirection == 'asc'" icon="sort-down" class="mr-1" font-scale="1.5" color=" rgb(164 0 255)"/>
                              <b-icon v-if="this.sortDirection == 'desc'" icon="sort-up-alt" class="mr-1" font-scale="1.5" color=" rgb(164 0 255)"/>
                            </span>
                          <input
                        v-model="searchDealerQuery"
                        style="
                        border: 1px solid #d0e1f9;
                        padding: 10px;
                        border-radius: 10px;
                        font-size: 10px;
                        height: 20px;
                        float: right;
                        outline: none;"
                          placeholder="Search by Location Name/Address"
                        />
                        </div>
                      </div>
                      <div style="height: auto"  v-if="this.paginatedLocationsDataLists.length > 0">
                        <div
                          v-for="(item, index) in this
                            .paginatedLocationsDataLists"
                          :key="index"
                        >
                          <b-card
                            class="mainReviewLeftCard"
                            :class="{ highlighted: highlightedCard === index }"
                          >
                            <div style="float: right">
                              {{ item.rating }}
                              <span>
                                <template
                                  v-for="(item, index) in Math.floor(
                                    item.rating
                                  )"
                                >
                                  <b-icon
                                    :key="item.locationId"
                                    style="margin-left: 5px;"
                                    icon="star-fill"
                                    color="orange"
                                  />
                                </template>
                                <template v-if="item.rating % 1 !== 0">
                                  <b-icon
                                    :key="item.locationId"
                                    style="margin-left: 5px;"
                                    icon="star-half"
                                    color="orange"
                                  /> 
                                </template>
                              </span>
                            </div>
                            <div
                              style="color: #200E6B ; font-weight: bold; cursor: pointer; text-align: left; font-size: 12px; width: 60%;"
                              @click="
                                reviesData(item.review, item.locationId, index)
                              "
                            >
                            <span style="color: rgb(164 0 255);font-size: 16px;">
                              {{ item.listingName }}
                             </span>
                             <br/>
                             <br/>
                             <span>
                              {{ item.address }}
                             </span>
                            </div>
                            <div
                              style="display: flex; float: right; min-height: 1vh; margin-top: 20px; width: 100%; text-align: end;"
                            >
                              <div style="width: 100%">
                                <b>{{ item.reviewCount }}</b> Reviews
                              </div>
                            </div>
                          </b-card>
                        </div>
                      </div>
                    </div>
                    <div style="margin-top: 3%; margin-left: 15px;"  v-if="this.paginatedLocationsDataLists.length > 0">
                      <b-pagination
                        :value="1"
                        first-number
                        last-number
                        :total-rows="reviewLocationsData.length"
                        :per-page="itemsPerPage"
                        align="left"
                        style="margin-right: 20px;"
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mt-1 mb-1"
                        v-model="currentPage"
                      >
                        <template #prev-text>
                          <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                          <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                      </b-pagination>
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col cols="6" class="reviewRight">
                <div>
                  <div>
                    <div v-if="this.reviewsData.length === 0">
                      <div
                        style="padding: 100px; margin-top:7rem; margin-left: 30%"
                      >
                      No reviews yet!
                      </div>
                    </div>
                    <div class="table-content-row " v-else>
                      <div
                        style="margin-top: 10px; margin-right: 35px; 
                         margin-bottom: 10px; width: 99%; display: flex; justify-content: space-between;"
                      >
                        <!-- <button @click="sortByRating">Sort by Rating</button> -->
                        <div
                          style="color: rgb(32, 14, 107); font-weight: bold; margin-left: 10px;"
                        >
                          Reviewers
                        </div>
                        <input
                          v-model="searchReviewsQuery"
                          style="
                          border: 1px solid #d0e1f9;
                          padding: 10px;
                          border-radius: 10px;
                          font-size: 11px;
                          margin-right: 10px;
                          height: 20px;
                          outline: none;
                        "
                          placeholder="Search By Reviewer"
                        />
                      </div>
                      <div style="height:auto;">
                        <div
                          v-for="(item, index) in paginatedReviewDataLists"
                          :key="index"
                        >
                          <b-card class="mainReviewRightCard">
                            <div>
                              <div
                                style="display: flex; justify-content: space-between; height: 4vh;"
                              >
                                <div>
                                  <div>
                                    <img
                                      :src="item.reviewer.profilePhotoUrl"
                                      :alt="item.reviewer.profilePhotoUrl"
                                      style="width: 35px; height: 35px; float:left; margin-left: 10px;"
                                    /><span
                                      style="margin-left: 5px !important; text-align: left;font-size: 12px; font-weight: 500;"
                                      >{{ item.reviewer.displayName }}</span
                                    >
                                  </div>
                                </div>
                                <div>
                                  <div>
                                    <b-icon
                                      v-for="n in getStarCount(item.starRating)"
                                      :key="n"
                                      style="margin-left: 5px;"
                                      icon="star-fill"
                                      color="orange"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div
                                v-b-tooltip.hover
                                :title="item.comment"
                                class="rightReviewCommet"
                              >
                                <span>
                                  {{ cropText(item.comment, 10) }}
                                </span>
                              </div>
                              <div class="rightButtomContainer">
                                <div style="margin-left: 3.5rem;">
                                  <!-- <div>{{ item.comment }}</div> -->
                                  <div
                                    style="font-size: 11px; font-weight: 500;"
                                  >
                                    Added on :
                                    {{
                                      new Date(item.createTime).toLocaleString(
                                        "en-US",
                                        {
                                          hour12: false,
                                        }
                                      )
                                    }}
                                  </div>
                                </div>
                                <div>
                                  <div class="action-container">
                                    <div class="rightButtonContainer">
                                      <b-button
                                        pill
                                        size="sm"
                                        variant="primary"
                                        @click="openApprovalReviewModal(item)"
                                      >
                                        <span style="font-size: 11px;">
                                          {{
                                            item.reviewReply
                                              ? "Edit Reply"
                                              : "Reply"
                                          }}</span
                                        >
                                        <b-icon icon="reply-fill" size="12" />
                                      </b-button>
                                      <!-- <b-button
                                    pill
                                    size="sm"
                                    style="margin-left: 10px;"
                                    variant="link"
                                    @click="likeButtonForReview"
                                  >
                                    Like
                                    <b-icon
                                      font-scale="1"
                                      :icon="
                                        isLikedReviews ? 'heart-fill' : 'heart'
                                      "
                                    />
                                  </b-button> -->
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </b-card>
                        </div>
                      </div>
                    </div>
                    <div class="float-right" style="margin-right: 15px;">
                      <b-pagination
                        v-if="this.paginatedReviewDataLists.length > 0"
                        :value="1"
                        first-number
                        last-number
                        :total-rows="reviewsData.length"
                        :per-page="itemsReviesPerPage"
                        align="right"
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mt-1 mb-1"
                        v-model="currentReviewPage"
                      >
                        <template #prev-text>
                          <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                          <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                      </b-pagination>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row  v-if="this.paginatedLocationsDataLists.length === 0">
              <b-col cols="12" class="text-center mt-4">
                <div style="width: 20%; display: inline-block;" class="mt-4">
                  <span v-if="isLocationsFetched == false">
                    Data not found for this client.
                  </span>
                </div>
              </b-col>
            </b-row>
          </b-overlay>
        </b-card>
      </div>
    </b-overlay>
    <b-modal
      v-model="isOpenApprovalReviewModal"
      hide-footer
      hide-header
      centered
      id="my-modal"
    >
      <div
        class="modal-item"
        style="display: flex; justify-content: space-between;"
      >
        <div class="pr-1 pt-0 edit-form" style="margin-top: 10px;">
          <h3 class="app-title sm">
            Reply to Review
          </h3>
        </div>
        <div>
          <b-button
            @click="closeApprovalReviewModal"
            variant="flat-primary"
            style="margin-top:-11px; margin-right:-20px"
            ><feather-icon icon="XIcon"></feather-icon
          ></b-button>
        </div>
      </div>
      <div class="mb-6 pt-2">
        <b-form-group>
          <label for="client-name"
            ><img
              style="width: 40px; height: 40px; margin-right: 10px;"
              :src="reviewDetailsImg"
              alt="content"
            />
            <span
              style="text-transform: capitalize; font-size: 15px; font-weight: 600;"
              >{{ this.reviewDetailsName }}</span
            ></label
          >
        </b-form-group>
        <b-form-group>
          <label for="client-name"
            >Description <span class="text-danger ">*</span></label
          >
          <b-form-textarea
            id="textarea-lazy"
            placeholder="Add a Description"
            rows="3"
            v-model="reviewDetailsComment"
            @input="updateCharacterCount"
          ></b-form-textarea>
          <span style="font-size:11px; float:right; margin-top:2px"
            >{{ characterCount }}/1500</span
          >
          <span
            v-if="this.abouseContent !== ''"
            style="color: red; font-size: 10px; text-align: center; align-items: center; margin-left:0px;"
          >
            {{ abouseContent }}
          </span>
        </b-form-group>

        <div style="float: right;">
          <b-button variant="primary" @click="replyToReviewer">
            {{ this.reviewDetailsComment == "" ? "Reply" : "Edit Reply" }}
            <feather-icon icon="SendIcon" size="13" />
            <b-spinner small v-if="this.isGetApprovalSend"></b-spinner>
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BPagination,
  BModal,
  BFormGroup,
  BFormTextarea,
  BBadge,
  BOverlay,
  BSpinner,
} from "bootstrap-vue";
import axios from "axios";
import { getUserToken } from "@/auth/utils";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BPagination,
    BModal,
    BFormGroup,
    BFormTextarea,
    BBadge,
    BOverlay,
    BSpinner,
  },
  data() {
    return {
      clientidInitallY:{},
      replyModal: null,
      isSortingByRating: false,
      searchDealerQuery: "",
      searchReviewsQuery: "",
      isGetApprovalSend: false,
      reviewLocationId: "",
      abouseContent: "",
      paramsName: "",
      reviewID: "",
      reviewDetailsName: "",
      reviewDetailsImg: "",
      reviewDetailsComment: "",
      isReviewClientFetched: false,
      reviewsData: [],
      reviewLocationsData: [],
      isLocationsFetched: false,
      selectedReviewLocationGroup: "",
      descriptionReview: "",
      characterCount: 0,
      isOpenApprovalReviewModal: false,
      isLikedReviews: false,
      selectedReviewsClient: "",
      paginatedDataNumber1: 1,
      itemsPerPage: 5,
      itemsReviesPerPage: 5,
      currentPage: 1,
      currentReviewPage: 1,
      fetchClientsList: [],
      pageLength: 0,
      highlightedCard: null,
      sortBy: 'rating', 
      sortDirection: 'asc',
    };
  },
  props: {
    inputReviewIdProps: {
      type: Object,
    },
  },
  computed: {
    paginatedLocationsDataLists() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    const filteredLocations = this.filterLocations();
    if (!filteredLocations.length) {
      return [];
    }

    const sortedLocations = this.sortLocations(filteredLocations);

    if (sortedLocations.length > 0) {
      this.reviesData(sortedLocations[0].review, sortedLocations[0].locationId, 0);
    }

    return sortedLocations.slice(startIndex, endIndex);
  },
   paginatedReviewDataLists() {
      const startIndex = (this.currentReviewPage - 1) * this.itemsReviesPerPage;
      const endIndex = startIndex + this.itemsReviesPerPage;
      const filteredReviews = this.reviewsData
        .filter((review) => {
          const titleMatches =
            review.reviewer.displayName &&
            review.reviewer.displayName
              .toLowerCase()
              .includes(this.searchReviewsQuery.toLowerCase());
          const bodyMatches =
            review.comment &&
            review.comment
              .toLowerCase()
              .includes(this.searchReviewsQuery.toLowerCase());
          return titleMatches || bodyMatches;
        })
        .slice(startIndex, endIndex);
      return filteredReviews;
    },
  },
  watch: {
    currentPage(newPage) {
      this.handleReviewClick(
        this.paginatedLocationsDataLists[0]?.review,
        this.paginatedLocationsDataLists[0]?.locationId,
        0
      );
    },
  },
  methods: {
    filterLocations() {
    const query = this.searchDealerQuery.toLowerCase();

    return Object.values(this.reviewLocationsData)
      .filter(location => {
        const nameMatches = location.listingName && location.listingName.toLowerCase().includes(query);
        const addressMatches = location.address && location.address.toLowerCase().includes(query);

        return nameMatches || addressMatches;
      });
  },
    handleReviewClick(review, locationId, index) {
      // Call your method with the required parameters
      this.reviesData(review, locationId, index);
    },
    sortLocations(locations) {
      return locations.sort((a, b) => {
        const multiplier = this.sortDirection === 'asc' ? 1 : -1;
        return multiplier * (b[this.sortBy] - a[this.sortBy]);
      });
    },
    toggleSortOrder() {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    },
    replyToReviewer() {
      this.isGetApprovalSend = true;
      axios
        .put(
          `${process.env.VUE_APP_SERVER_URL}/api/gmb/reviewReply`,
          {
            clientID: this.selectedReviewsClient,
            name: this.paramsName,
            comment: this.reviewDetailsComment,
            reviewID: this.reviewID,
            locationID: this.reviewLocationId,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.isGetApprovalSend = false;
          this.isOpenApprovalReviewModal = false;
        })
        .catch((error) => {
          this.abouseContent = error.response.data.error;
          this.isGetApprovalSend = false;
          // this.isOpenApprovalReviewModal = false;
        });
    },
    cropText(text, maxLength) {
      if (!text) {
        return "";
      }
      const words = text.split(" ");
      if (words.length > maxLength) {
        const croppedWords = words.slice(0, maxLength);
        return `${croppedWords.join(" ")}...`;
      } else {
        return text;
      }
    },

    getStarCount(ratingString) {
      const ratingMap = {
        one: 1,
        two: 2,
        three: 3,
        four: 4,
        five: 5,
      };

      const numericRating = ratingMap[ratingString.toLowerCase()] || 0;
      const fullStars = Math.floor(numericRating);
      const hasHalfStar = numericRating % 1 !== 0;

      return hasHalfStar ? fullStars + 1 : fullStars;
    },
    reviesData(arr, id, index) {
      this.currentReviewPage = 1;
      this.reviewsData = [];
      this.reviewLocationId = id;
      this.reviewsData = arr;
      this.highlightedCard = index;
    },
    likeButtonForReview() {
      this.isLikedReviews = true;
    },
    updateCharacterCount() {
      this.characterCount = this.reviewDetailsComment.length;
      if (this.characterCount > 1500) {
        this.reviewDetailsComment = this.reviewDetailsComment.slice(0, 1500);
        this.characterCount = 1500;
      }
    },
    closeApprovalReviewModal() {
      this.isOpenApprovalReviewModal = false;
    },
    openApprovalReviewModal(item) {
      this.replyModal = item;
      this.reviewID = this.replyModal.reviewId;
      this.paramsName = this.replyModal.name;
      this.reviewDetailsName = this.replyModal.reviewer.displayName;
      this.reviewDetailsImg = this.replyModal.reviewer.profilePhotoUrl;
      if (this.replyModal?.reviewReply?.comment) {
        this.reviewDetailsComment = this.replyModal.reviewReply.comment;
      } else {
        this.reviewDetailsComment = "";
      }
      this.isOpenApprovalReviewModal = true;
    },
    fetchClients() {
      this.isReviewClientFetched = true;
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/client/get`,
          {
            limit: 100,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
              "Content-type": "application/json",
            },
          }
        )
        .then((response) => {
          this.fetchClientsList = response.data.clients.filter((client) => {
            return client.gmb && client.gmb.accountID.length;
          });
          this.setIntialClient();
          this.isReviewClientFetched = false;
        })
        .catch((error) => {
          console.log(error);
          this.isReviewClientFetched = false;
        });
    },
    getReviewLocations() {
      this.isLocationsFetched = true;
      this.reviewsData = [];
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/gmb/reviews?clientID=${this.selectedReviewsClient}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.reviewLocationsData = response.data;
          this.isLocationsFetched = false;
        })
        .catch((error) => {
          this.isLocationsFetched = false;
          console.log(error);
        });
    },
    fetchGMBCustomers() {
      this.clientidInitallY = {}
      const slug = this.$route.params.slug;
      if(!slug) return;
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/api/client/${slug}`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          this.clientidInitallY = response.data.client
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
    getReviewsData() {
      this.getReviewLocations();
    },
    setIntialClient() {
      if (!this.$route.params.slug) {
        this.selectedReviewsClient = this.inputReviewIdProps._id;
        this.getReviewLocations();
      }else{
        this.selectedReviewsClient = this.clientidInitallY._id;
        this.getReviewLocations();
      }
    },
  },
  mounted() {
    this.fetchGMBCustomers()
    this.fetchClients();
  },
};
</script>

<style>
.form-container1 {
  width: 40%;
}
.dashboard-overlay-reviews{
  min-height: 60vh;
  max-height: auto;
}
.rightButtomContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  height: auto;
}
.rightReviewCommet {
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
  height: auto;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}
.rightButtonContainer {
  display: flex;
  justify-content: space-between;
  margin-top: -10px;
  margin-left: -6.5rem;
  width: 15vh;
}
.mainReviewContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  background-color: #f5f7f8;
  min-height: 65vh;
}
.mainReviewLeftContainer {
  min-height: 60vh;
}
.mainReviewLeftCard {
  margin: 10px;
  height: auto;
  width: 100%;
}
.mainReviewRightCard {
  margin: 10px;
  height: auto;
  padding: 2px;
  width: 95%;
}
.filter-container-for-reviews {
  padding-left: 2em;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 8px;
  border-radius: 5px;
}
.full-width-table {
  width: 100%;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  padding: 8px;
  border-radius: 5px;
}
.full-width-table-reviews {
  width: 100%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
  padding: 8px;
  border-radius: 5px;
}
.table-content {
  padding: 20px;
  text-align: center;
  align-items: center;
}
.full-width-table td {
  text-align: center;
  border-bottom: 0.5px solid #f1efef;
  width: 15%;
}
.full-width-table th {
  text-align: center;
  color: rgb(88, 88, 88);
  font-weight: 600;
  font-size: 13px;
}
.gmb-review-select {
  border-right: 10px solid transparent;
  outline: 1px solid #d8d6de;
  width: 100% !important;
  height: 30px;
  border: 0px;
  padding-left: 10px;
  background-color: white;
  border-radius: 5px;
  color: rgb(163, 153, 153);
}
.float-right {
  float: right !important;
}
.action-container {
  float: right;
  width: 20% !important;
}
.pending {
  padding: 10px;
  border-radius: 10px;
  color: #dfce97;
  background-color: #f3f1d1;
  font-weight: bold;
}
.rejected {
  padding: 10px;
  border-radius: 10px;
  color: #f29c9b;
  background-color: #fdf0ec;
  font-weight: bold;
}
.approved {
  padding: 10px;
  border-radius: 10px;
  color: #95baa9;
  background-color: #c6e1d3;
  font-weight: bold;
}
@media only screen and (max-width: 767px) {
  .mainReviewContainer {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
    background-color: #f5f7f8;
    min-height: 65vh;
  }
  .mainReviewLeftContainer {
    margin-left: 2rem;
    min-height: 58vh;
  }
  .mainReviewLeftCard {
    height: auto;
    width: 95%;
  }
  .mainReviewRightCard {
    margin: 10px;
    height: auto;
    /* margin-left: -15%;
     */
    width: 110%;
  }
  .rightButtonContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 0px;
    margin-right: -30px;
    width: 30vh;
  }
  .mainContainerForReview {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
  }
  .reviewLeft {
    width: 100vh !important;
  }
  .reviewRight {
    width: 100vh !important;
  }
  .rightButtomContainer {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    height: auto;
    margin-top: 10px;
    text-align: left;
  }
  .action-container {
    align-items: center;
    justify-content: center;
    left: auto;
    right: auto;
    margin-top: 10px;
  }
  .rightReviewCommet {
    text-align: left;
    margin-top: 20px;
    height: auto;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
  }
  .gmb-review-select {
    width: 200px;
  }
}
@media only screen and (min-width: 767px) and (max-width: 1281px) {
  .mainReviewContainer {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
    background-color: #f5f7f8;
    min-height: 5vh;
  }
  .mainReviewLeftContainer {
    min-height: 58vh;
  }
  .mainReviewLeftCard {
    height: auto;
    width: 96%;
  }
  .mainReviewRightCard {
    margin: 10px;
    height: auto;
    /* width: 105%; */
    /* margin-left: -4rem; */
  }
  .rightButtonContainer {
    display: flex;
    justify-content: space-between;
    margin-top: -10px;
    margin-right: -10px;
    width: 20vh;
  }
}
.highlighted {
  background-color: #e7dff9;
}
</style>
