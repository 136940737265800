<template>
  <div>
    <b-card class="tabs-container screenAlignment">
      <b-tabs fill>
        <b-tab v-if="this.summaryEnabled === 'true'"  title="Summary" active @click="changeTab('Summary')"></b-tab>
        <b-tab v-if="this.organicEnabled === 'true'" title="Organic" @click="changeTab('Organic')"> </b-tab>
        <b-tab v-if="this.paidEnabled === 'true'" title="Paid" @click="changeTab('Paid')"> </b-tab>
        <b-tab v-if="this.postsEnabled === 'true'" title="Posts" @click="changeTab('Posts')"> </b-tab>
        <b-tab v-if="this.reviewsEnabled === 'true'" title="Reviews" @click="changeTab('Reviews')">
        </b-tab>
        <b-tab v-if="this.geoEnabled === 'true'" title="Geo Analytics" @click="changeTab('HeatMap')" disabled>
        </b-tab>
        <b-tab v-if="this.storesEnabled === 'true'" title="Store Level" @click="changeTab('StoreLevel')" disabled>
        </b-tab>
      </b-tabs>
    </b-card>
    <div v-if="activeTab === 'Summary'">
      <b-overlay :show="isDataLoading" rounded="sm" class="gmb-dashboard-overlay">
        <b-row class="TopTilesContainer">
          <b-col cols="6">
            <b-card class="SelectCard SelectCardResp" style="height:170px">
              <div style="display: flex; justify-content: space-between; gap: 2rem; margin-top:-12px">
                <div class="form-group form-container1">
                  <label for="monthSelect" class="form-label">Select Client:</label>
                  <div class="select-container1 summaryClientSelecr">
                    <select v-model="selectedClient" class="gmb-client-select " @change="getAllGMBSummaryData">
                      <option value="">Select Client</option>
                      <option v-for="onetype in fetchClientsList" :value="onetype" class="form-control " id="lg_type"
                        name="lg_type" v-bind:key="onetype._id">
                        {{ onetype.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div style="display: flex; align-items: left; gap:1rem; float: left;">
                  <div style="display: flex; align-items: center;">
                    <input type="radio" name="some-radios" style="width: 15px; height: 15px; cursor: pointer;"
                      v-model="summaryTabFilterType" value="monthRange" />
                    <label style="margin-left: 0.5rem;">Monthly </label>
                  </div>
                  <div style="display: flex; align-items: center;">
                    <input type="radio" name="some-radios" style="width: 15px; height: 15px; cursor: pointer;"
                      v-model="summaryTabFilterType" value="doubleDateRange" />
                    <label style="margin-left: 0.5rem;">Custom Dates</label>
                  </div>
                </div>
              </div>

              <div class="SelectBoxSummaryContainer">
                <div class="form-group form-container1" v-if="summaryTabFilterType == 'doubleDateRange'">
                  <div style="width: 50%;">
                    <CustomComponent selectorDirections="right" @fetchNewDataBasisOnRange1="getReportData1"
                      @fetchNewDataBasisOnRange2="getReportData2" />
                  </div>

                </div>
                <div class="form-group form-container1" v-if="summaryTabFilterType == 'monthRange'">
                  <label for="monthSelect" class="form-label"> Month:</label>
                  <div class="select-container">
                    <b-form-select id="monthSelect" v-model="selectedMonth" @change="getAllGMBSummaryData"
                      class="custom-select summaryClientSelecr">
                      <option v-for="month in months" :disabled="isMonthSummayDisabled(month)" :value="month.value"
                        :key="month.value">
                        {{ month.label }}
                      </option>
                    </b-form-select>
                  </div>
                </div>
                <div class="form-group form-container" v-if="summaryTabFilterType == 'monthRange'">
                  <label for="yearSelect" class="form-label"> Year:</label>
                  <div class="select-container">
                    <b-form-select id="yearSelect" v-model="selectedYear" @change="getAllGMBSummaryData"
                      class="summaryClientYearSelecr">
                      <option v-for="year in years" :value="year.label" :key="year.value">
                        {{ year.label }}
                      </option>
                    </b-form-select>
                  </div>
                </div>
              </div>
            </b-card>
          </b-col>
          <b-col cols="2">
            <b-card style="height: 170px;" class="totalStoreCards">
              <div style="display: flex; justify-content: space-between; margin-top: 20px;">
                <b-card-title style="width: 240px; justify-content: center; align-items: center; text-align: center;">
                  <div class="text-center" style="margin-bottom: 5px;">
                    <b-avatar size="40" variant="light-primary">
                      <b-icon icon="shop" font-scale="1.5" />
                    </b-avatar>
                  </div>
                  <h3 class="text-center text-primary" style="margin-bottom:  0 !important; font-size: 15px !important;">
                    {{ this.gmbData.totalStores }}
                  </h3>
                  <h6 class="text-center" style="margin-bottom: -30px !important; font-size: 9px !important;">
                    Total Stores
                  </h6>
                </b-card-title>

                <b-card-title style="width: 240px;">
                  <div class="text-center" style="margin-bottom: 5px;">
                    <b-avatar size="40" variant="light-warning">
                      <feather-icon size="24" icon="EyeIcon" />
                    </b-avatar>
                  </div>
                  <h3 class="text-center text-primary" style="margin-bottom:  0 !important; font-size: 15px !important;">
                    {{ this.gmbData.totalStoreVisits }}
                  </h3>
                  <h6 class="text-center" style="margin-bottom: -30px !important; font-size: 9px !important;">
                    Total Store Visit
                  </h6>
                </b-card-title>
              </div>
            </b-card>
          </b-col>
          <b-col cols="4">
            <b-card class="card-tiny-line-stats thirdTiles storeDetails" body-class="pb-50" style="height:170px">
              <div class="mt-2">
                <h6 class="text-xs text-center text-capital">
                  <!-- {{ this.costEfficientStoreTitle }} -->
                  <b-avatar size="40" variant="light-primary" style="margin-right: 5px;">
                    <b-icon icon="shop" font-scale="1.5" />
                  </b-avatar>
                  Store with Highest Traffic
                </h6>
                <div class="text-center" style="margin-bottom: 5px; margin-top: 10px;"></div>

                <div class="">
                  <h6 class="text-xs  overflow-header  font-weight-bolder text-center app-title" v-b-tooltip.hover
                    :title="this.costEfficientStoreName">
                    {{ this.costEfficientStoreName }}
                  </h6>
                  <h6 class="overflow-header text-center app-title" style="font-size: 9px;" v-b-tooltip.hover
                    :title="this.costEfficientStoreAddress">
                    {{ this.costEfficientStoreAddress }}
                  </h6>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>
        <b-row class="graph-container">
          <b-col cols="3" class="graph-card">
            <b-card class="card-tiny-line-stats" body-class="pb-50" style="height: 352px;">
              <h6 class="text-xs text-center">Cost Per Store Visit <feather-icon size="15" icon="InfoIcon" color="grey"
                  style="margin-bottom: 3px; cursor: pointer; margin-left: 3px"
                  v-b-tooltip.hover.x="this.gmbData.label2" /></h6>
              <h2 class="font-weight-bolder text-center app-title lg" v-if="this.costPerStoreVisitData > 0">
                {{ this.costPerStoreVisitData == null ? 0 : this.costPerStoreVisitData }}

              </h2>
              <h2 v-else class="font-weight-bolder text-center app-title lg">
                0
              </h2>
              <!-- chart -->
              <div style="justify-content: center; align-items: center;  margin-left: auto; margin-right: auto;">
                <apexchart type="bar" :options="options" :series="seriesPerData"></apexchart>
              </div>
              <!-- <div v-if="this.summaryTabFilterType == 'doubleDateRange'"
                style="display:flex; justify-content:space-around; font-size: 10px; gap:0.5rem; color:black; margin-top:-20"
              >
                <div
                  style="display:flex; justify-content:space-between; gap:0.2rem"
                >
                  <span v-if="this.startDate1 != ''">{{ formatChartsDate(this.startDate1) }}</span> -
                  <span v-if="this.endDate1 != '' ">{{ formatChartsDate(this.endDate1) }}</span>
                </div>
                <div
                  style="display:flex; justify-content:space-between;gap:0.2rem"
                >
                  <span v-if="this.startDate2 != '' ">{{ formatChartsDate(this.startDate2) }}</span> -
                  <span v-if="this.endDate2 != '' ">{{ formatChartsDate(this.endDate2) }}</span>
                </div>
              </div> -->
              <div style="text-align: center;">
                <h6 class="text-center mt-2" style=" font-size: 11px !important; margin-top: -15px;">
                  <span>
                    <!-- {{ this.costPerStoreVisitDeltaData }} -->
                    {{ Math.abs(parseFloat(this.costPerStoreVisitDeltaData)) }} %
                  </span>

                  <feather-icon v-if="parseInt(this.costPerStoreVisitDeltaData) < 0" size="15" icon="ChevronsDownIcon"
                    :color="this.costPerStoreVisitDeltaColor" style="margin-bottom: 3px;" />
                  <feather-icon v-if="parseInt(this.costPerStoreVisitDeltaData) > 0" size="15" icon="ChevronsUpIcon"
                    :color="this.costPerStoreVisitDeltaColor" style="margin-bottom: 3px;" />
                  <feather-icon size="15" icon="InfoIcon" color="grey"
                    style="margin-bottom: 3px; cursor: pointer; margin-left: 3px" v-b-tooltip.hover.x="tooltipText" />
                </h6>
              </div>
            </b-card>
          </b-col>
          <!-- <b-col cols="4" class="graph-card">
            <b-card class="card-tiny-line-stats" body-class="pb-50" style="height: 352px;">
              <h6 class="text-xs text-center chart-text">
                Incremental SV <feather-icon size="15" icon="InfoIcon" color="grey"
                  style="margin-bottom: 3px; cursor: pointer; margin-left: 3px"
                  v-b-tooltip.hover.x="this.gmbData.label2" />
              </h6>
              <h2 class="font-weight-bolder text-center app-title lg">
                {{ this.incrementalStoreVisitsCount == null ? 0 : this.incrementalStoreVisitsCount }}

              </h2>
              <div style="justify-content: center; align-items: center; margin-left: auto; margin-right: auto;">
                <apexchart type="bar" :options="optionsSummaryNew" :series="seriesSummaryNewCPIIncremental"></apexchart>
              </div>
            </b-card>
          </b-col>
          <b-col cols="4" class="graph-card">
            <b-card class="card-tiny-line-stats" body-class="pb-50" style="height: 352px;">
              <h6 class="text-xs text-center chart-text">CPISV <feather-icon size="15" icon="InfoIcon" color="grey"
                  style="margin-bottom: 3px; cursor: pointer; margin-left: 3px"
                  v-b-tooltip.hover.x="this.gmbData.label2" /></h6>
              <h2 class="font-weight-bolder text-center app-title lg">
                {{ this.costPerIncrementalStoreVisitCount == null ? 0 : this.costPerIncrementalStoreVisitCount }}

              </h2>
              <div style="justify-content: center; align-items: center;  margin-left: auto; margin-right: auto; ">
                <apexchart type="bar" :options="optionsSummaryNew" :series="seriesSummaryNewCPICPISV"></apexchart>
              </div>
            </b-card>
          </b-col> -->
          <b-col cols="3" class="graph-card">
            <b-card class="card-tiny-line-stats" body-class="pb-50" style="height: 352px;">
              <h6 class="text-xs text-center">Organic To Paid Store Visits <feather-icon size="15" icon="InfoIcon"
                  color="grey" style="margin-bottom: 3px; cursor: pointer; margin-left: 3px"
                  v-b-tooltip.hover.x="this.gmbData.label2" /></h6>
              <h2 class="font-weight-bolder text-center app-title lg">
                {{ this.organicToPaidStoreVisitsRatio == null ? 0 : this.organicToPaidStoreVisitsRatio }}

              </h2>
              <!-- chart -->
              <div style="justify-content: center; align-items: center; margin-left: auto; margin-right: auto;">
                <apexchart type="bar" :options="chartOragnicOptions" :series="seriesOraganicData"></apexchart>
              </div>
              <div style="text-align: center; margin-top: -20px;">
                <h6 class="text-center mt-2" style=" font-size: 11px !important; margin-top: -15px;">
                  {{ this.organicToPaidStoreVisitsratioPrevMonth }}
                  <feather-icon size="15" icon="InfoIcon" color="grey"
                    style="margin-bottom: 3px; cursor: pointer; margin-left: 3px"
                    v-b-tooltip.hover.x="tooltipRatioText" />
                </h6>
              </div>
            </b-card>
          </b-col>
          <b-col cols="3" class="graph-card">
            <b-card class="card-tiny-line-stats " body-class="pb-50" style="height: 352px;">
              <h6 class="text-xs  text-center">Driving Directions <feather-icon size="15" icon="InfoIcon" color="grey"
                  style="margin-bottom: 3px; cursor: pointer; margin-left: 3px"
                  v-b-tooltip.hover.x="this.gmbData.label2" /></h6>
              <h2 class="font-weight-bolder text-center  app-title lg">
                {{ this.drivingDirectionsData == null ? 0 : this.drivingDirectionsData }}
              </h2>
              <div style="justify-content: center; align-items: center; margin-left: auto; margin-right: auto;">
                <apexchart type="bar" ref="redar" :options="options" :series="seriesDrivingData"></apexchart>
              </div>
              <div style="text-align: center;">
                <h6 class="text-center mt-2" style=" font-size: 11px !important; margin-top: -15px;">
                  {{ Math.abs(parseFloat(this.drivingDirectionsDeltaData)) }} %
                  <span v-if="parseInt(this.drivingDirectionsDeltaData) < 0">
                    <feather-icon size="15" icon="ChevronsDownIcon" :color="this.drivingDirectionsDeltaColor"
                      style="margin-bottom: 3px;" />
                  </span>
                  <span v-if="parseInt(this.drivingDirectionsDeltaData) > 0">
                    <feather-icon size="15" icon="ChevronsUpIcon" :color="this.drivingDirectionsDeltaColor"
                      style="margin-bottom: 3px;" />
                  </span>
                  <feather-icon size="15" icon="InfoIcon" color="grey"
                    style="margin-bottom: 3px; cursor: pointer; margin-left: 3px" v-b-tooltip.hover.x="tooltipText" />
                </h6>
              </div>
            </b-card>
          </b-col>
          <b-col cols="3" class="graph-card">
            <b-card class="card-tiny-line-stats" body-class="pb-50" style="height: 352px;">
              <h6 class="text-xs text-center">Calls Done <feather-icon size="15" icon="InfoIcon" color="grey"
                  style="margin-bottom: 3px; cursor: pointer; margin-left: 3px"
                  v-b-tooltip.hover.x="this.gmbData.label2" /></h6>
              <h2 class="font-weight-bolder text-center app-title lg">
                {{ this.costDoneData == null ? 0 : this.costDoneData }}

              </h2>
              <!-- chart -->
              <div style="justify-content: center; align-items: center;  margin-left: auto; margin-right: auto; ">
                <apexchart type="bar" :options="options" :series="seriesCostDoneData"></apexchart>
              </div>
              <div style="text-align: center;">
                <h6 class="text-center mt-2" style=" font-size: 11px !important; margin-top: -15px;">
                  {{ Math.abs(parseFloat(this.costDoneDeltaData)) }} %
                  <feather-icon v-if="parseInt(this.costDoneDeltaData) < 0" size="15" icon="ChevronsDownIcon"
                    :color="this.costDoneDeltaDataColor" style="margin-bottom: 3px;" />
                  <feather-icon v-if="parseInt(this.costDoneDeltaData) > 0" size="15" icon="ChevronsUpIcon"
                    :color="this.costDoneDeltaDataColor" style="margin-bottom: 3px;" />
                  <feather-icon size="15" icon="InfoIcon" color="grey"
                    style="margin-bottom: 3px; cursor: pointer; margin-left: 3px" v-b-tooltip.hover.x="tooltipText" />
                </h6>
              </div>
            </b-card>
          </b-col>
        </b-row>
        <b-row class="graph-container">
    
        </b-row>
        <b-row class="graph-container graph-containerlast">
          <b-col cols="4">
            <b-card class="data-card">
              <b-card-title class="text-center  mb-4 ">
                <h6 class="text-primary">{{ chartOptions.title.text }}</h6>
              </b-card-title>
              <div style="display: flex; justify-content: space-around; font-size: 10px; color: rgb(162, 157, 157);">
                <div>
                  <span style="font-weight: bold;"> High Traffic Stores: </span>
                  {{ this.IncreaseTrifficCount }}
                </div>
                <div>
                  <span style="font-weight: bold;"> Low Traffic Stores: </span>
                  {{ this.DecreaseTrifficCount }}
                </div>
              </div>
              <div style="margin-top: -105px">
                <e-charts ref="line" autoresize :options="chartOptions" theme="theme-color" auto-resize></e-charts>
              </div>
            </b-card>
          </b-col>
          <b-col cols="4">
            <b-card class="data-card">
              <b-card-title class="text-center ">
                <h6 class="text-success">
                  High Traffic Store(s) {{ this.IncreaseTrifficCount }}
                </h6>
              </b-card-title>
              <div class="bottom-cardContainer" v-if="this.displayedIncreaseItems.length > 0">
                <label> Store </label>
                <label> Change over previous month </label>
              </div>
              <div v-else>
                <div class="text-center"
                  style="margin-bottom: 5px; justify-content: center; align-items: center; text-align: center; margin-top: 130px;">
                  <b-avatar size="80" variant="light-success">
                    <b-icon icon="shop" font-scale="3" />
                  </b-avatar>
                  <h6 class="text-success mt-2">0 store found</h6>
                </div>
              </div>
              <div :style="this.displayedIncreaseItems.length > 0
                ? 'min-height:225px'
                : ''
                ">
                <div class="bottom-cards" v-for="(item, index) in this.displayedIncreaseItems" :key="index">
                  <div class="bottom-card-item">
                    <h6 v-b-tooltip.hover style="font-size: 12px" class="overflow-text" :title="item.store">
                      {{ item.store }}
                    </h6>
                    <h6 style="font-size: 10px; width: 60px;">
                      {{ item.deltaPrevMonth }}
                      <feather-icon size="15" icon="ChevronsUpIcon" color="green" style="margin-bottom: 3px;" />
                    </h6>
                  </div>
                </div>
              </div>

              <div style="justify-content: center; margin-top: 30px; ">
                <b-pagination v-if="this.displayedIncreaseItems.length > 0" v-model="currentPage"
                  :total-rows="totalIncrease" :per-page="perPage" first-number last-number align="center"
                  prev-class="prev-item" next-class="next-item"></b-pagination>
              </div>
            </b-card>
          </b-col>
          <b-col cols="4">
            <b-card class="data-card">
              <b-card-title class="text-center ">
                <h6 class=" text-warning">
                  Low Traffic Store(s) {{ this.DecreaseTrifficCount }}
                </h6>
              </b-card-title>
              <div v-if="this.displayedDecreaseItems.length > 0">
                <div class="bottom-cardContainer">
                  <label> Store </label>
                  <label> Change over previous month </label>
                </div>
                <div style="min-height:225px">
                  <div class="bottom-cards" v-for="(item, index) in this.displayedDecreaseItems" :key="index">
                    <div class="bottom-card-item">
                      <h6 v-b-tooltip.hover style="font-size: 12px;" class="overflow-text" :title="item.store">
                        {{ item.store }}
                      </h6>
                      <h6 style="font-size: 10px; width: 60px;">
                        {{ Math.abs(parseFloat(item.deltaPrevMonth)) }} %
                        <feather-icon size="15" icon="ChevronsDownIcon" color="red" style="margin-bottom: 3px;" />
                      </h6>
                    </div>
                  </div>
                </div>
                <div style="justify-content: center; margin-top: 30px; height: 1;">
                  <b-pagination v-if="this.displayedDecreaseItems.length > 0" v-model="currentDecreasePage"
                    :total-rows="totalDecrease" :per-page="perPage" first-number last-number align="center"
                    prev-class="prev-item" next-class="next-item"></b-pagination>
                </div>
              </div>
              <div v-else>
                <div class="text-center"
                  style="margin-bottom: 5px; justify-content: center; align-items: center; text-align: center; margin-top: 130px;">
                  <b-avatar size="80" variant="light-warning">
                    <b-icon icon="shop" font-scale="3" />
                  </b-avatar>
                  <h6 class="text-warning mt-2">0 store found</h6>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-overlay>
    </div>
    <div v-if="activeTab === 'Organic'">
      <b-row>
        <b-col cols="12">
          <b-card class="SelectOrgCard">
            <div class="SelectBoxOrgContainer organicPage">
              <div class="form-group form-container-pages">
                <label for="monthSelect" class="form-label">
                  Select Client:</label>
                <div class="select-container1">
                  <select v-model="selectedClient" class="gmb-client-select" @change="getAllGMBOrganicData">
                    <option value="">Select Client</option>
                    <option v-for="onetype in fetchClientsList" :value="onetype" class="form-control" id="lg_type"
                      name="lg_type" v-bind:key="onetype._id">
                      {{ onetype.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group form-container-filter" style="margin-left:30px">
                <div style="display: flex; align-items: left; gap:1rem; float: left; margin-top: 30px;">
                  <div style="display: flex; align-items: center;">
                    <input type="radio" name="some-radios" style="width: 15px; height: 15px; cursor: pointer;"
                      v-model="summaryTabFilterType" value="monthRange" />
                    <label style="margin-left: 0.5rem;">Monthly</label>
                  </div>
                  <div style="display: flex; align-items: center;">
                    <input type="radio" name="some-radios" style="width: 15px; height: 15px; cursor: pointer;"
                      v-model="summaryTabFilterType" value="doubleDateRange" />
                    <label style="margin-left: 0.5rem;">Custom Dates</label>
                  </div>
                </div>
              </div>
              <div class="form-group form-container-filter" v-if="summaryTabFilterType == 'doubleDateRange'">
                <CustomComponent selectorDirections="left" @fetchNewDataBasisOnRange1="getReportData1"
                  @fetchNewDataBasisOnRange2="getReportData2" />
              </div>
              <div class="form-group form-container-filter" v-if="summaryTabFilterType == 'monthRange'">
                <label for="monthSelect" class="form-label"> Month:</label>
                <div class="select-container">
                  <b-form-select id="monthSelect" v-model="selectedMonth" @change="getAllGMBOrganicData"
                    class="custom-select">
                    <option v-for="month in monthsOrganic" :disabled="isMonthOrgDisabled(month)" :value="month.value"
                      :key="month.value">
                      {{ month.label }}
                    </option>
                  </b-form-select>
                </div>
              </div>

              <div class="form-group form-container-filter" v-if="summaryTabFilterType == 'monthRange'">
                <label for="yearSelect" class="form-label"> Year:</label>
                <div class="select-container">
                  <b-form-select id="yearSelect" v-model="selectedYear" @change="getAllGMBOrganicData">
                    <option v-for="year in years" :value="year.label" :key="year.label">
                      {{ year.label }}
                    </option>
                  </b-form-select>
                </div>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-overlay :show="isOrganicDataLoading || isStoreDataLoadProperly" rounded="sm" class="gmb-dashboard-overlay-organic">
        <b-row class="organicCards organicCardsRes">
          <b-col cols="4" class="graph-card cardOrg1">
            <b-card class="card-tiny-line-stats" body-class="pb-50">
              <h6 class="text-xs text-center">Shop Visits <feather-icon size="15" icon="InfoIcon" color="grey"
                  style="margin-bottom: 3px; cursor: pointer; margin-left: 3px"
                  v-b-tooltip.hover.x="this.organicLabel2" /></h6>
              <h2 class="font-weight-bolder text-center app-title lg">
                {{ this.orgStores == null ? 0 : this.orgStores }}

              </h2>
              <!-- chart -->
              <div style="justify-content: center; align-items: center;  margin-left: auto; margin-right: auto; ">
                <apexchart type="bar" height="290" :options="optionsOrganic" :series="seriesShopData"></apexchart>
              </div>
              <div style="text-align: center;">
                <h6 class="text-center mt-1" style=" font-size: 11px !important; margin-top: -15px;">
                  {{ Math.abs(parseFloat(this.orgStoresPer)) }} %
                  <feather-icon v-if="parseInt(this.orgStoresPer) < 0" size="15" icon="ChevronsDownIcon" color="red"
                    style="margin-bottom: 3px;" />
                  <feather-icon v-if="parseInt(this.orgStoresPer) > 0" size="15" icon="ChevronsUpIcon" color="green"
                    style="margin-bottom: 3px;" />
                  <feather-icon size="15" icon="InfoIcon" color="grey"
                    style="margin-bottom: 3px; cursor: pointer; margin-left: 3px" v-b-tooltip.hover.x="tooltipText" />
                </h6>
              </div>
            </b-card>
          </b-col>

          <b-col cols="8">
            <b-card class="orgCard">
              <h6 class="text-xs text-center">Total Views <feather-icon size="15" icon="InfoIcon" color="grey"
                  style="margin-bottom: 3px; cursor: pointer; margin-left: 3px"
                  v-b-tooltip.hover.x="this.organicLabel2" /></h6>
              <h2 class="font-weight-bolder text-center app-title lg">
                {{ this.orgTotalViews == null ? 0 : this.orgTotalViews }}

              </h2>
              <apexchart class="chartOrg" type="bar" height="280" :options="chartViewsOptions" :series="seriesViews">
              </apexchart>
              <div style="display: flex; justify-content: center; gap: 15rem; margin-top: 20px; margin-left:3.5rem">
                <div style="text-align: center; margin-top: 5px;">
                  <h6 class="text-center " style=" font-size: 11px !important; margin-top: -15px;">
                    {{ Math.abs(parseFloat(this.orgTotalViewsPer)) }} %
                    <feather-icon v-if="parseInt(this.orgTotalViewsPer) < 0" size="15" icon="ChevronsDownIcon" color="red"
                      style="margin-bottom: 3px;" />
                    <feather-icon v-if="parseInt(this.orgTotalViewsPer) > 0" size="15" icon="ChevronsUpIcon" color="green"
                      style="margin-bottom: 3px;" />
                    <feather-icon size="15" icon="InfoIcon" color="grey"
                      style="margin-bottom: 3px; cursor: pointer; margin-left: 3px" v-b-tooltip.hover.x="tooltipText" />
                  </h6>
                </div>
                <div style="text-align: center; margin-top: 5px;">
                  <h6 class="text-center " style=" font-size: 11px !important; margin-top: -15px;">
                    {{ Math.abs(parseFloat(this.orgMapViewsPer)) }} %
                    <feather-icon v-if="parseInt(this.orgMapViewsPer) < 0" size="15" icon="ChevronsDownIcon" color="red"
                      style="margin-bottom: 3px;" />
                    <feather-icon v-if="parseInt(this.orgMapViewsPer) > 0" size="15" icon="ChevronsUpIcon" color="green"
                      style="margin-bottom: 3px;" />
                    <feather-icon size="15" icon="InfoIcon" color="grey"
                      style="margin-bottom: 3px; cursor: pointer; margin-left: 3px" v-b-tooltip.hover.x="tooltipText" />
                  </h6>
                </div>
                <div style="text-align: center; margin-top: 5px;">
                  <h6 class="text-center " style=" font-size: 11px !important; margin-top: -15px;">
                    {{ Math.abs(parseFloat(this.orgSearchViewsPer)) }} %
                    <feather-icon v-if="parseInt(this.orgSearchViewsPer) < 0" size="15" icon="ChevronsDownIcon"
                      color="red" style="margin-bottom: 3px;" />
                    <feather-icon v-if="parseInt(this.orgSearchViewsPer) > 0" size="15" icon="ChevronsUpIcon"
                      color="green" style="margin-bottom: 3px;" />
                    <feather-icon size="15" icon="InfoIcon" color="grey"
                      style="margin-bottom: 3px; cursor: pointer; margin-left: 3px" v-b-tooltip.hover.x="tooltipText" />
                  </h6>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>
        <b-row class="organicCards organicCardsRes">
          <b-col cols="8">
            <b-card class="orgCard">
              <h6 class="text-xs text-center">Total Actions <feather-icon size="15" icon="InfoIcon" color="grey"
                  style="margin-bottom: 3px; cursor: pointer; margin-left: 3px"
                  v-b-tooltip.hover.x="this.organicLabel2" /></h6>
              <h2 class="font-weight-bolder text-center app-title lg">
                {{ this.orgTotalActions == null ? 0 : this.orgTotalActions }}

              </h2>
              <apexchart type="bar" height="280" :options="chartActionsOptions" :series="seriesActions"></apexchart>
              <div style="display: flex; justify-content: center; gap: 15rem; margin-top: 20px; margin-left:3rem">
                <div style="text-align: center; margin-top: 5px;">
                  <h6 class="text-center" style=" font-size: 11px !important; margin-top: -15px;">
                    {{ Math.abs(parseFloat(this.orgTotalActionsPer)) }} %
                    <feather-icon v-if="parseInt(this.orgTotalActionsPer) < 0" size="15" icon="ChevronsDownIcon"
                      color="red" style="margin-bottom: 3px;" />
                    <feather-icon v-if="parseInt(this.orgTotalActionsPer) > 0" size="15" icon="ChevronsUpIcon"
                      color="green" style="margin-bottom: 3px;" />
                    <feather-icon size="15" icon="InfoIcon" color="grey"
                      style="margin-bottom: 3px; cursor: pointer; margin-left: 3px" v-b-tooltip.hover.x="tooltipText" />
                  </h6>
                </div>
                <div style="text-align: center; margin-top: 5px;">
                  <h6 class="text-center" style=" font-size: 11px !important; margin-top: -15px;">
                    {{ Math.abs(parseFloat(this.orgActionPercentage)) }} %
                    <feather-icon v-if="parseInt(this.orgActionPercentage) < 0" size="15" icon="ChevronsDownIcon"
                      color="red" style="margin-bottom: 3px;" />
                    <feather-icon v-if="parseInt(this.orgActionPercentage) > 0" size="15" icon="ChevronsUpIcon"
                      color="green" style="margin-bottom: 3px;" />
                    <feather-icon size="15" icon="InfoIcon" color="grey"
                      style="margin-bottom: 3px; cursor: pointer; margin-left: 3px" v-b-tooltip.hover.x="tooltipText" />
                  </h6>
                </div>
                <div style="text-align: center; margin-top: 5px;">
                  <h6 class="text-center" style=" font-size: 11px !important; margin-top: -15px;">
                    {{ Math.abs(parseFloat(this.orgWebsiteActionsPer)) }} %
                    <feather-icon v-if="parseInt(this.orgWebsiteActionsPer) < 0" size="15" icon="ChevronsDownIcon"
                      color="red" style="margin-bottom: 3px;" />
                    <feather-icon v-if="parseInt(this.orgWebsiteActionsPer) > 0" size="15" icon="ChevronsUpIcon"
                      color="green" style="margin-bottom: 3px;" />
                    <feather-icon size="15" icon="InfoIcon" color="grey"
                      style="margin-bottom: 3px; cursor: pointer; margin-left: 3px" v-b-tooltip.hover.x="tooltipText" />
                  </h6>
                </div>
              </div>
            </b-card>
          </b-col>
          <b-col cols="4" class="graph-card cardOrg1">
            <b-card class="card-tiny-line-stats" body-class="pb-50">
              <h6 class="text-xs text-center">Ratings <feather-icon size="15" icon="InfoIcon" color="grey"
                  style="margin-bottom: 3px; cursor: pointer; margin-left: 3px"
                  v-b-tooltip.hover.x="this.organicLabel2" /></h6>
              <h2 class="font-weight-bolder text-center app-title lg">
                {{ this.ratings == null ? 0 : this.ratings }}

              </h2>
              <!-- chart -->
              <div style="justify-content: center; align-items: center;  margin-left: auto; margin-right: auto; ">
                <apexchart type="bar" height="290" :options="optionsOrganic" :series="seriesRatingData"></apexchart>
              </div>
              <div style="text-align: center;">
                <h6 class="text-center mt-1" style=" font-size: 11px !important; margin-top: -15px;">
                  {{ Math.abs(parseFloat(this.ratingsPer)) }} %
                  <feather-icon v-if="parseInt(this.ratingsPer) < 0" size="15" icon="ChevronsDownIcon" color="red"
                    style="margin-bottom: 3px;" />
                  <feather-icon v-if="parseInt(this.ratingsPer) > 0" size="15" icon="ChevronsUpIcon" color="green"
                    style="margin-bottom: 3px;" />
                  <feather-icon size="15" icon="InfoIcon" color="grey"
                    style="margin-bottom: 3px; cursor: pointer; margin-left: 3px" v-b-tooltip.hover.x="tooltipText" />
                </h6>
              </div>
            </b-card>
          </b-col>
        </b-row>
        <b-card  style="margin-left:-8px; margin-right:-8px">
        <div class="schedule-hearder">
          <div>
            <b-card-title>
              <h3 class="app-title mdsm">Individual store data</h3>
            </b-card-title>
          </div>
          <div>
            <input class="search-field" type="text" v-model="searchTerm" placeholder="Search by name/address" />
            <b-button style="margin-left: 10px; margin-bottom: 4px;" @click="exportIndividiualStoreData"
              class="exportButton" variant="primary"><span style="font-size: 12px;">Export</span><feather-icon
                icon="DownloadIcon" size="15" style="margin-left: 5px;" /></b-button>
          </div>
        </div>
        <div v-if="isLoadingIndividualData" class="spinner">
          <b-spinner small></b-spinner>
        </div>
        <vue-good-table class="custom-data-table" ref="goodTableIndividual" :rows="storeWiseGMBData"
          :columns="IndividualDatatableColumns" :search-options="{
          enabled: true,
          externalQuery: searchTerm,
          }" 
          
          :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }">
          <template slot="table-row" slot-scope="props">
            <div v-if="props.column.label == 'Store Address'">
              <span v-b-tooltip.hover  :title="props.row.address">
                {{cropText(props.row.address , 10)  }}
              </span>
            </div>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1"></div>
              <div>
                <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number
                  align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                  v-model="paginatedDataNumber2" @input="(value) => props.pageChanged({ currentPage: value })">
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card>
      </b-overlay>
    </div>
    <div v-if="activeTab === 'Paid'">
      <b-row>
        <b-col cols="12">
          <b-card class="SelectOrgCard">
            <div class="SelectBoxOrgContainer organicPage">
              <div class="form-group form-container-pages">
                <label for="monthSelect" class="form-label">
                  Select Client:</label>
                <div class="select-container1">
                  <select v-model="selectedClient" class="gmb-client-select" @change="getAllGMBPaidSummaryData">
                    <option value="">Select Client</option>
                    <option v-for="onetype in fetchClientsList" :value="onetype" class="form-control" id="lg_type"
                      name="lg_type" v-bind:key="onetype._id">
                      {{ onetype.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group form-container-filter" style="margin-left:30px">
                <div style="display: flex; align-items: left; gap:1rem; float: left; margin-top: 30px;">
                  <div style="display: flex; align-items: center;">
                    <input type="radio" name="some-radios" style="width: 15px; height: 15px; cursor: pointer;"
                      v-model="summaryTabFilterType" value="monthRange" />
                    <label style="margin-left: 0.5rem;">Monthly</label>
                  </div>
                  <div style="display: flex; align-items: center;">
                    <input type="radio" name="some-radios" style="width: 15px; height: 15px; cursor: pointer;"
                      v-model="summaryTabFilterType" value="doubleDateRange" />
                    <label style="margin-left: 0.5rem;">Custom Dates</label>
                  </div>
                </div>
              </div>
              <div class="form-group form-container-filter" v-if="summaryTabFilterType == 'doubleDateRange'">
                <CustomComponent selectorDirections="left" @fetchNewDataBasisOnRange1="getReportData1"
                  @fetchNewDataBasisOnRange2="getReportData2" />
              </div>
              <div class="form-group form-container-filter" v-if="summaryTabFilterType == 'monthRange'">
                <label for="monthSelect" class="form-label"> Month:</label>
                <div class="select-container">
                  <b-form-select id="monthSelect" v-model="selectedMonth" @change="getAllGMBPaidSummaryData"
                    class="custom-select">
                    <option v-for="month in monthsOrganicSummaryPaid" :disabled="isMonthSummayPaidDisabled(month)"
                      :value="month.value" :key="month.value">
                      {{ month.label }}
                    </option>
                  </b-form-select>
                </div>
              </div>
              <div class="form-group form-container-filter" v-if="summaryTabFilterType == 'monthRange'">
                <label for="yearSelect" class="form-label"> Year:</label>
                <div class="select-container">
                  <b-form-select id="yearSelect" @change="getAllGMBPaidSummaryData" v-model="selectedYear">
                    <option v-for="year in years" :value="year.label" :key="year.value">
                      {{ year.label }}
                    </option>
                  </b-form-select>
                </div>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-overlay :show="isSummaryPaidLoading || isSummaryPaidDetailsLoading || isLoadingPaidStorewiseData" rounded="sm" class="gmb-dashboard-overlay">
        <!-- <b-row>
          <b-col cols="5">
            <div class="SelectCard">
              <div class="SelectBoxContainer">
                <div class="form-group form-container1" style="width:300px">
                  <label for="monthSelect" class="form-label text-white">
                    Select Client:</label
                  >
                  <div class="select-container1">
                    <select
                      v-model="selectedClient"
                      class="gmb-client-select"
                      @change="getAllGMBPaidSummaryData"
                    >
                      <option value="">Select Client</option>
                      <option
                        v-for="onetype in fetchClientsList"
                        :value="onetype._id"
                        class="form-control"
                        id="lg_type"
                        name="lg_type"
                        v-bind:key="onetype._id"
                      >
                        {{ onetype.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="form-group form-container1" style="width:300px; ">
                  <label for="monthSelect" class="form-label text-white">
                    Month:</label
                  >
                  <div class="select-container">
                    <b-form-select
                      id="monthSelect"
                      v-model="selectedMonth"
                      @change="getAllGMBPaidSummaryData"
                      class="custom-select"
                    >
                      <option
                        v-for="month in monthsOrganicSummaryPaid"
                        :disabled="isMonthSummayPaidDisabled(month)"
                        :value="month.value"
                        :key="month.value"
                      >
                        {{ month.label }}
                      </option>
                    </b-form-select>
                  </div>
                </div>
                <div
                  class="form-group form-container text-white"
                  style="width:200px"
                >
                  <label for="yearSelect" class="form-label text-white">
                    Year:</label
                  >
                  <div class="select-container">
                    <b-form-select
                      id="yearSelect"
                      v-model="selectedYear"
                    >
                      <option
                        v-for="year in years"
                        :value="year.value"
                        :key="year.value"
                      >
                        {{ year.label }}
                      </option>
                    </b-form-select>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row> -->
        <b-row class="paidCardContainer">
          <b-col cols="4">
            <b-card style="height:240px" class="paidCardItem">
              <div style="height: 157px;" class="totalPaidCards">
                <div style="text-align: center; justify-content: center; align-items: center;">
                  <b-card-title style="text-align: center; margin-top:-10px">
                    <h6 class="text-center" style="font-size: 11px !important;">
                      Total Store Visits <feather-icon size="15" icon="InfoIcon" color="grey"
                        style="margin-bottom: 3px; cursor: pointer; margin-left: 3px"
                        v-b-tooltip.hover.x="this.summaryPaidLabel2" />
                    </h6>
                    <div class="text-center" style="font-size: 11px !important;">
                      {{ this.paidSummayTotalStoreVisitCount == null ? 0 : this.paidSummayTotalStoreVisitCount }}

                    </div>
                    <apexchart style="
                      position: absolute;
                      top: 50%;
                      left: 50%;
                      transform: translate(-50%, -5%);
                      padding: 10px;" type="bar" height="150" width="350" :options="optionsPaidSummary"
                      :series="seriesPaidSummaryStorePerData"></apexchart>

                    <div style="text-align: center; transform: translate(-0%, 900%);">
                      <h6 class="text-center" style=" font-size: 11px !important; ">
                        <span v-if="this.paidSummayCostPerPer === '-'">
                          {{ this.paidSummayTotalStoreVisitCountPer }}
                        </span>
                        <span v-else>
                          {{ Math.abs(this.paidSummayTotalStoreVisitCountPer) }}
                        </span>
                        %
                        <feather-icon v-if="parseInt(this.paidSummayTotalStoreVisitCountPer) < 0
                          " size="15" icon="ChevronsDownIcon" :color="this.paidSummmaryTotalStoreVisitColor"
                          style="margin-bottom: 3px;" />
                        <feather-icon v-if="parseInt(this.paidSummayTotalStoreVisitCountPer) > 0
                          " size="15" icon="ChevronsUpIcon" :color="this.paidSummmaryTotalStoreVisitColor"
                          style="margin-bottom: 3px;" />
                        <feather-icon size="15" icon="InfoIcon" color="grey"
                          style="margin-bottom: 3px; cursor: pointer; margin-left: 3px"
                          v-b-tooltip.hover.x="tooltipText" />
                      </h6>
                    </div>
                  </b-card-title>
                </div>
              </div>
            </b-card>
          </b-col>
          <b-col cols="4">
            <b-card style="height:240px" class="paidCardItem">
              <div style="height: 157px;" class="totalPaidCards">
                <div style="text-align: center; justify-content: center; align-items: center;">
                  <b-card-title style="margin-top:-10px ;text-align: center;">
                    <h6 class="text-center" style="font-size: 11px !important;">
                      Cost Per Store Visit <feather-icon size="15" icon="InfoIcon" color="grey"
                        style="margin-bottom: 3px; cursor: pointer; margin-left: 3px"
                        v-b-tooltip.hover.x="this.summaryPaidLabel2" />
                    </h6>
                    <div class="text-center" style="font-size: 11px !important;">
                      {{ this.paidSummayCostPerCountFormated == null ? 0 : this.paidSummayCostPerCountFormated }}
                    </div>
                    <apexchart style="
                      position: absolute;
                      top: 50%;
                      left: 50%;
                      transform: translate(-50%, -5%);
                      padding: 10px;" type="bar" height="150" width="350" :options="optionsPaidSummary"
                      :series="seriesPaidSummaryCostData"></apexchart>
                    <div style="text-align: center; transform: translate(-0%, 900%);">
                      <h6 class="text-center" style=" font-size: 11px !important; ">
                        <span v-if="this.paidSummayCostPerPer === '-'">
                          {{ this.paidSummayCostPerPer }}
                        </span>
                        <span v-else>
                          {{ Math.abs(parseFloat(this.paidSummayCostPerPer)) }}
                        </span>
                        %
                        <feather-icon v-if="parseInt(this.paidSummayCostPerPer) < 0" size="15" icon="ChevronsDownIcon"
                          :color="this.paidSummmaryCostVisitColor" style="margin-bottom: 3px;" />
                        <feather-icon v-if="parseInt(this.paidSummayCostPerPer) > 0" size="15" icon="ChevronsUpIcon"
                          :color="this.paidSummmaryCostVisitColor" style="margin-bottom: 3px;" />
                        <feather-icon size="15" icon="InfoIcon" color="grey"
                          style="margin-bottom: 3px; cursor: pointer; margin-left: 3px"
                          v-b-tooltip.hover.x="tooltipText" />
                      </h6>
                    </div>
                  </b-card-title>
                </div>
              </div>
            </b-card>
          </b-col>
          <b-col cols="4">
            <b-card style="height:240px" class="paidCardItem">
              <div style="height: 157px;" class="totalPaidCards">
                <div style="text-align: center; justify-content: center; align-items: center;">
                  <b-card-title style="text-align: center; margin-top:-10px">
                    <h6 class="text-center" style="font-size: 11px !important;">
                      Total Spends <feather-icon size="15" icon="InfoIcon" color="grey"
                        style="margin-bottom: 3px; cursor: pointer; margin-left: 3px"
                        v-b-tooltip.hover.x="this.summaryPaidLabel2" />
                    </h6>
                    <div class="text-center" style="font-size: 11px !important;">
                      {{ this.paidSummayTotalSpendsCountFormated == null ? 0 : this.paidSummayTotalSpendsCountFormated }}

                    </div>
                    <apexchart style="
                      position: absolute;
                      top: 50%;
                      left: 50%;
                      transform: translate(-50%, -5%);
                      padding: 10px;" type="bar" height="150" width="350" :options="optionsPaidSummary"
                      :series="seriesPaidSummaryTotalSpendsData"></apexchart>
                    <div style="text-align: center; transform: translate(-0%, 900%);">
                      <h6 class="text-center" style=" font-size: 11px !important; ">
                        <span v-if="this.paidSummayCostPerPer === '-'">
                          {{ this.paidSummayTotalSpendsCountPer }}
                        </span>
                        <span v-else>
                          {{
                            Math.abs(
                              parseFloat(this.paidSummayTotalSpendsCountPer)
                            )
                          }}
                        </span>
                        %
                        <feather-icon v-if="parseInt(this.paidSummayTotalSpendsCountPer) < 0
                            " size="15" icon="ChevronsDownIcon" :color="this.paidSummmaryTotalSpendsColor"
                          style="margin-bottom: 3px;" />
                        <feather-icon v-if="parseInt(this.paidSummayTotalSpendsCountPer) > 0
                          " size="15" icon="ChevronsUpIcon" :color="this.paidSummmaryTotalSpendsColor"
                          style="margin-bottom: 3px;" />
                        <feather-icon size="15" icon="InfoIcon" color="grey"
                          style="margin-bottom: 3px; cursor: pointer; margin-left: 3px"
                          v-b-tooltip.hover.x="tooltipText" />
                      </h6>
                    </div>
                  </b-card-title>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>
        <!-- <b-card  style="margin-left:-8px; margin-right:-8px">
        <div class="schedule-hearder">
          <div>
            <b-card-title>
              <h3 class="app-title mdsm">Store Wise Paid Data Report</h3>
            </b-card-title>
          </div>
          <div>
            <input class="search-field" type="text" v-model="searchTerm" placeholder="Search by name/address" />
            <b-button style="margin-left: 10px; margin-bottom: 4px;" @click="exportIndividiualStoreData"
              class="exportButton" variant="primary"><span style="font-size: 12px;">Export</span><feather-icon
                icon="DownloadIcon" size="15" style="margin-left: 5px;" /></b-button>
          </div>
        </div>
        <div v-if="isLoadingPaidStorewiseData" class="spinner">
          <b-spinner small></b-spinner>
        </div>
        <vue-good-table class="custom-data-table" ref="goodTableIndividual" :rows="PaidStorewiseData"
          :columns="StorewiseDatatableColumns" :search-options="{
          enabled: true,
          externalQuery: searchStoreTerm,
          }" 
          
          :pagination-options="{
          enabled: true,
          perPage: pageStoreLength,
        }">
          <template slot="table-row" slot-scope="props">
            <div v-if="props.column.label == 'Store Address'">
              <span v-b-tooltip.hover  :title="props.row.address">
                {{cropText(props.row.address , 10)  }}
              </span>
            </div>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1"></div>
              <div>
                <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number
                  align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                  v-model="paginatedDataNumber2" @input="(value) => props.pageChanged({ currentPage: value })">
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card> -->
        <b-row>
          <b-col cols="12">
            <b-card>
              <b-card-title>
                <h3 class="app-title mdsm text-left">Paid</h3>
              </b-card-title>
              <MultiColorTable :PaidData="paidTableData" TableType="Paid" />
            </b-card>
          </b-col>
        </b-row>
      </b-overlay>
    </div>
    <div v-if="activeTab === 'StoreLevel'">
      <b-overlay :show="isSummaryStoreLevelLoading" rounded="sm" class="gmb-dashboard-overlay">
        <b-row>
          <b-col cols="5">
            <div class="SelectCard">
              <div class="SelectBoxContainer">
                <div class="form-group form-container1" style="width:300px">
                  <label for="monthSelect" class="form-label text-white">
                    Select Client:</label>
                  <div class="select-container1">
                    <select v-model="selectedClient" class="gmb-client-select"
                      @change="getAllGMBStoreLevelSummaryDataChange">
                      <option value="">Select Client</option>
                      <option v-for="onetype in fetchClientsList" :value="onetype._id" class="form-control" id="lg_type"
                        name="lg_type" v-bind:key="onetype._id">
                        {{ onetype.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="form-group form-container1" style="width:300px; ">
                  <label for="monthSelect" class="form-label text-white">
                    Month:</label>
                  <div class="select-container">
                    <b-form-select id="monthSelect" v-model="selectedSummaryStoreLevelMonth"
                      @change="getAllGMBStoreLevelSummaryDataChange" class="custom-select">
                      <option v-for="month in monthsOrganicSummaryStoreLevel"
                        :disabled="isMonthSummayStoreLevelDisabled(month)" :value="month.value" :key="month.value">
                        {{ month.label }}
                      </option>
                    </b-form-select>
                  </div>
                </div>
                <div class="form-group form-container text-white" style="width:200px">
                  <label for="yearSelect" class="form-label text-white">
                    Year:</label>
                  <div class="select-container">
                    <b-form-select id="yearSelect" v-model="selectedYear" @change="getAllGMBStoreLevelSummaryDataChange">
                      <option v-for="year in years" :value="year.label" :key="year.value">
                        {{ year.label }}
                      </option>
                    </b-form-select>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-card>
              <b-card-title>
                <h3 class="app-title text-left text-capitalize text-xs">
                  Store with higher traffic than previous month
                </h3>
              </b-card-title>
              <MultiColorTable :PaidData="StoreLevelIncrease" TableType="StoreLevel" />
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-card>
              <b-card-title>
                <h3 class="app-title text-left text-capitalize text-xs">
                  Store with lower traffic than previous month
                </h3>
              </b-card-title>
              <MultiColorTable :PaidData="StoreLevelDecrease" TableType="StoreLevel" />
            </b-card>
          </b-col>
        </b-row>
      </b-overlay>
    </div>
    <div style="margin-left:-7px !important ;margin-right:-7px !important ;" v-if="activeTab === 'Posts'">
      <LocalPost :inputLocalIdProps="this.selectedClient" :isThroughGmbDash="true" />
    </div>
    <div v-if="activeTab === 'Reviews'">
      <GmbReviews :inputReviewIdProps="this.selectedClient" />
    </div>
    <div v-if="activeTab === 'HeatMap'">
      <HeatMapPage />
    </div>
  </div>
</template>
<script>
import {
  BCard,
  BCardTitle,
  BTab,
  BTabs,
  BFormSelect,
  BCol,
  BRow,
  BAvatar,
  BIcon,
  BTable,
  BPagination,
  BOverlay,
  BButton,
  BSpinner
} from "bootstrap-vue";
import MultiColorTable from "./MultiColorTable.vue";
import LocalPost from "../Client/LocalPost.vue";
import axios from "axios";
import ECharts from "vue-echarts";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/chart/pie";
import { getUserToken } from "@/auth/utils";
import AppEchartDoughnut from "@core/components/charts/echart/AppEchartDoughnut.vue";
import theme from "@core/components/charts/echart/theme.json";
import VueApexCharts from "vue-apexcharts";
import CustomComponent from "../Client/custom-component/CustomComponent.vue";
import GmbReviews from '../clientFeature/GmbReviews.vue'
import HeatMapPage from '../clientFeature/HeatMapPage.vue'
import { VueGoodTable } from "vue-good-table";
ECharts.registerTheme("theme-color", theme);
import Cookies from "js-cookie";
import useJwt from "@/auth/jwt/useJwt";
export default {
  components: {
    BTabs,
    BTab,
    BCard,
    BCardTitle,
    BFormSelect,
    BCol,
    BRow,
    BAvatar,
    BIcon,
    BTable,
    AppEchartDoughnut,
    ECharts,
    apexchart: VueApexCharts,
    BPagination,
    BOverlay,
    MultiColorTable,
    CustomComponent,
    LocalPost,
    GmbReviews,
    HeatMapPage,
    BButton,
    VueGoodTable,
    BSpinner
  },
  data() {
    return {
      clientidInitallY:{},
      geoEnabled: null,
      summaryEnabled: null,
      organicEnabled: null,
      paidEnabled: null,
      postsEnabled: null,
      reviewsEnabled: null,
      storesEnabled: null,
      isStoreDataLoadProperly:false,
      storeWiseGMBData:[],
      pageLength:8,
      pageStoreLength:8,
      paginatedDataNumber2: 1,
      AllSummarytableData:[],
      isLoadingIndividualData:false,
      isLoadingPaidStorewiseData:false,
      PaidStorewiseData:[],
      searchTerm: "",
      optionsSummaryNew: {
        chart: {
          height: 250,
          type: "line",
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        title: {
          align: "left",
        },
        grid: {
          row: {
            colors: ["transparent", "transparent"],
            opacity: 0.5,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true,
          },
        },
        dataLabels: {
          enabled: true,
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories: ["Previous Month", "Current Month"],
        },
      },
      seriesSummaryNewCPIIncremental: [
        {
          name: "Incremental SV",
          data: [0, 0],
        },
      ],
      seriesSummaryNewCPICPISV: [
        {
          name: "CPISV",
          data: [0, 0],
        },
      ],
      // costPerIncrementalStoreVisitColor: "",
      costPerIncrementalStoreVisitCount: 0,
      // costPerIncrementalStoreVisitCountFormatted: 0,
      // costPerIncrementalStoreVisitCountPrevMonth: 0,
      // costPerIncrementalStoreVisitCountPrevMonthFormatted: 0,
      // costPerIncrementalStoreVisitDeltaPrevMonth: 0,
      // incrementalStoreVisitsColor: "",
      incrementalStoreVisitsCount: 0,
      // incrementalStoreVisitsCountFormatted: 0,
      // incrementalStoreVisitsCountPrevMonth: 0,
      // incrementalStoreVisitsCountPrevMonthFormatted: 0,
      // incrementalStoreVisitsDeltaPrevMonth: 0,
      isSummaryPaidDetailsLoading: false,
      startDate1: "",
      endDate1: "",
      startDate2: "",
      endDate2: "",
      summaryTabFilterType: "monthRange",
      compare: 0,
      iClickedItem: false,
      organicLabel1: "",
      organicLabel2: "",
      summaryPaidLabel1: "",
      summaryPaidLabel2: "",
      seriesViews: [
        {
          name: "January",
          data: [0, 0, 0],
        },
        {
          name: "February",
          data: [0, 0, 0],
        },
      ],
      seriesActions: [
        {
          name: "January",
          data: [0, 0, 0],
        },
        {
          name: "February",
          data: [0, 0, 0],
        },
      ],
      chartViewsOptions: {
        chart: {
          type: "bar",
          height: 250,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              position: "top",
            },
          },
        },
        colors: ["#662E9B", "#F9C80E"],
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val;
          },
          offsetY: -15,
          style: {
            fontSize: "9px",
            colors: ["#8358BE"],
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
        xaxis: {
          categories: ["Total Views", "Map Views", "Search Views"],
          labels: {
            style: {
              fontSize: "10px",
              color: "#333",
              fontFamily: "Arial, sans-serif",
            },
          },
        },
      },
      chartActionsOptions: {
        chart: {
          type: "bar",
          height: 250,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              position: "top",
            },
          },
        },

        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val;
          },
          offsetY: -15,
          style: {
            fontSize: "9px",
            colors: ["#8358BE"],
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
        xaxis: {
          categories: ["Total Actions", "Call Actions", "Website Actions"],
          labels: {
            style: {
              fontSize: "10px",
              color: "#333",
              fontFamily: "Arial, sans-serif",
            },
          },
        },
      },
      seriesPaidSummaryStorePerData: [
        {
          name: "Total Store Visits",
          data: [0, 0],
        },
      ],
      seriesPaidSummaryTotalSpendsData: [
        {
          name: "Cost Per Store Visit",
          data: [0, 0],
        },
      ],
      seriesPaidSummaryCostData: [
        {
          name: "Total Spends",
          data: [0, 0],
        },
      ],
      seriesPerData: [
        {
          name: "Ratio",
          data: [0, 0],
        },
      ],
      seriesOraganicData: [
        {
          name: "Net Profit",
          data: [0, 0],
        },
        {
          name: "Revenue",
          data: [0, 0],
        },
      ],
      seriesCostDoneData: [
        {
          name: "Calls Done",
          data: [0, 0],
        },
      ],
      seriesRatingData: [
        {
          name: "Ratings",
          data: [0, 0],
        },
      ],
      seriesDirectionsActionsData: [
        {
          name: "Directions Actions",
          data: [0, 0],
        },
      ],
      seriesTotalSearchesData: [
        {
          name: "Total Search",
          data: [0, 0],
        },
      ],
      seriesShopData: [
        {
          name: "Shop Visit",
          data: [0, 0],
        },
      ],
      seriesDrivingData: [
        {
          name: "Driving",
          data: [0, 0],
        },
      ],
      seriesSummaryNewCPI: [
        {
          name: "Paid SV",
          data: [0, 0],
        },
      ],
      seriesSummaryNewCPIIncremental: [
        {
          name: "Incremental SV",
          data: [0, 0],
        },
      ],
      seriesSummaryNewCPSV: [
        {
          name: "CPSV",
          data: [0, 0],
        },
      ],
      seriesSummaryNewCPICPISV: [
        {
          name: "CPISV",
          data: [0, 0],
        },
      ],
      optionsOrganic: {
        chart: {
          height: 250,
          type: "line",
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        title: {
          align: "left",
        },
        grid: {
          row: {
            colors: ["transparent", "transparent"],
            opacity: 0.5,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories: ["August", "September"],
        },
      },
      optionsPaidSummary: {
        chart: {
          height: 150,
          width: 360,
          type: "line",
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        title: {
          align: "left",
        },
        grid: {
          row: {
            colors: ["transparent", "transparent"],
            opacity: 0.5,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories: ["August", "September"],
        },
      },
      chartOragnicOptions: {
        chart: {
          height: 350,
          type: "line",
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "45%",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: ["August", "September"],
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return " " + val + " ";
            },
          },
        },
      },
      options: {
        chart: {
          height: 195,
          type: "line",
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        title: {
          align: "left",
        },
        grid: {
          row: {
            colors: ["transparent", "transparent"],
            opacity: 0.5,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true,
          },
        },
        dataLabels: {
          enabled: true,
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories: ["August", "September"],
        },
      },
      optionsSummaryNew: {
        chart: {
          height: 150,
          type: "line",
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        title: {
          align: "left",
        },
        grid: {
          row: {
            colors: ["transparent", "transparent"],
            opacity: 0.5,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true,
          },
        },
        dataLabels: {
          enabled: true,
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories: ["Previous Month", "Current Month"],
        },
      },
      chartTheme: "light",
      fetchClientsList: [],
      costEfficientStoreName: "",
      costEfficientStoreAddress: "",
      gmbData: [],
      organicDashboardData: [],
      activeTab: "Summary",
      selectedClient: {},
      selectedClientSummary: "",
      selectedOrganicClient: "",
      selectedMonth: new Date().getMonth(),
      selectedMonth: new Date().getMonth(),
      selectedSummaryStoreLevelMonth: new Date().getMonth(),
      // selectedMonth: new Date().getMonth(),
      selectedYear: '2024',
      years: [{ label: "2023", value: 0 }, { label: "2024", value: 1 }],
      costPerStoreVisitData: 0,
      costPerStoreVisitPrevData: 0,
      costPerStoreVisitDeltaData: 0,
      costPerStoreVisitDeltaColor: "",
      drivingDirectionsData: 0,
      drivingDirectionsPrevData: 0,
      drivingDirectionsDeltaData: 0,
      drivingDirectionsDeltaColor: "",
      costDoneData: 0,
      costDonePrevData: 0,
      costDoneDeltaData: 0,
      costDoneDeltaDataColor: "",
      organicToPaidStoreVisitsRatio: "",
      organicToPaidStoreVisitsratioPrevMonth: "",
      costEfficientStoreCode: "",
      costEfficientStoreVisit: 0,
      costEfficientStoreTitle: "",
      IncreaseTriffic: [],
      IncreaseTrifficCount: 0,
      DecreaseTriffic: [],
      DecreaseTrifficCount: 0,
      currentPage: 1,
      currentDecreasePage: 1,
      perPage: 4,
      isDataLoading: false,
      isOrganicDataLoading: false,
      chartOptions: {
        title: {
          text: "High Traffic Stores vs Low Traffic Stores",
          x: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
        },
        legend: {
          orient: "vertical",
          x: "left",
          data: ["Label 1", "Label 2"],
        },
        series: [
          {
            name: "High Traffic Stores vs Low Traffic Stores",
            type: "pie",
            radius: "50%",
            center: ["50%", "60%"],
            data: [
              { value: this.IncreaseTrifficCount, name: "High Traffic Store" },
              { value: this.DecreaseTrifficCount, name: "Low Traffic Store" },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      },
      paidSummaryData: [],
      isSummaryPaidLoading: false,
      isSummaryStoreLevelLoading: false,
      paidTableData: [],
      months: [
        { label: "January", value: 0 },
        { label: "February", value: 1 },
        { label: "March", value: 2 },
        { label: "April", value: 3 },
        { label: "May", value: 4 },
        { label: "June", value: 5 },
        { label: "July", value: 6 },
        { label: "August", value: 7 },
        { label: "September", value: 8 },
        { label: "October", value: 9 },
        { label: "November", value: 10 },
        { label: "December", value: 11 },
      ],
      monthsOrganic: [
        { label: "January", value: 0 },
        { label: "February", value: 1 },
        { label: "March", value: 2 },
        { label: "April", value: 3 },
        { label: "May", value: 4 },
        { label: "June", value: 5 },
        { label: "July", value: 6 },
        { label: "August", value: 7 },
        { label: "September", value: 8 },
        { label: "October", value: 9 },
        { label: "November", value: 10 },
        { label: "December", value: 11 },
      ],
      monthsOrganicSummaryPaid: [
        { label: "January", value: 0 },
        { label: "February", value: 1 },
        { label: "March", value: 2 },
        { label: "April", value: 3 },
        { label: "May", value: 4 },
        { label: "June", value: 5 },
        { label: "July", value: 6 },
        { label: "August", value: 7 },
        { label: "September", value: 8 },
        { label: "October", value: 9 },
        { label: "November", value: 10 },
        { label: "December", value: 11 },
      ],
      monthsOrganicSummaryStoreLevel: [
        { label: "January", value: 0 },
        { label: "February", value: 1 },
        { label: "March", value: 2 },
        { label: "April", value: 3 },
        { label: "May", value: 4 },
        { label: "June", value: 5 },
        { label: "July", value: 6 },
        { label: "August", value: 7 },
        { label: "September", value: 8 },
        { label: "October", value: 9 },
        { label: "November", value: 10 },
        { label: "December", value: 11 },
      ],
      tooltipText: "Change over previous month",
      tooltipRatioText: "previous month ratio",
      ratings: 0,
      prevRatings: 0,
      ratingsPer: 0,
      orgStores: 0,
      orgStoresPrev: 0,
      orgStoresPer: 0,
      orgDirectionActions: 0,
      orgDirectionActionsPrev: 0,
      orgDirectionActionsPer: 0,
      orgTotalSearches: 0,
      orgTotalSearchesPrev: 0,
      orgTotalSearchesPer: 0,
      orgDiscSearches: 0,
      orgDiscSearchesPrev: 0,
      orgDiscSearchesPer: 0,
      orgMapViews: 0,
      orgMapViewsPrev: 0,
      orgMapViewsPer: 0,
      orgWebsiteActions: 0,
      orgWebsiteActionsPrev: 0,
      orgWebsiteActionsPer: 0,
      orgCallActions: 0,
      orgCallActionsPrev: 0,
      orgActionPercentage: 0,
      orgTotalViews: 0,
      orgTotalViewsPrev: 0,
      orgTotalViewsPer: 0,
      orgSearchViews: 0,
      orgSearchViewsPrev: 0,
      orgSearchViewsPer: 0,
      orgTotalActions: 0,
      orgTotalActionsPrev: 0,
      orgTotalActionsPer: 0,
      orgDirectSearch: 0,
      orgDirectSearchPrev: 0,
      orgDirectSearchPer: 0,
      paidSummayCostPerCount: 0,
      paidSummayTotalSpendsCount: 0,
      paidSummayTotalStoreVisitCount: 0,
      paidSummmaryCostVisitColor: "",
      paidSummayCostPerPrev: 0,
      paidSummayTotalSpendsCountPrev: 0,
      paidSummayTotalStoreVisitCountPrev: 0,
      paidSummmaryTotalSpendsColor: "",
      paidSummayCostPerPer: 0,
      paidSummayTotalSpendsCountPer: 0,
      paidSummayTotalStoreVisitCountPer: 0,
      paidSummmaryTotalStoreVisitColor: "",
      paidSummayCostPerCountFormated: "",
      paidSummayTotalSpendsCountFormated: "",
      chartPaidOptions: {},
      StoreLevelIncrease: [],
      StoreLevelDecrease: [],
      newSummaryPageLoad: false,
      getSummaryChartData: [],
      costPerIncrementalStoreVisitColor: "",
      costPerIncrementalStoreVisitCount: 0,
      costPerIncrementalStoreVisitCountFormatted: 0,
      costPerIncrementalStoreVisitCountPrevMonth: 0,
      costPerIncrementalStoreVisitCountPrevMonthFormatted: 0,
      costPerIncrementalStoreVisitDeltaPrevMonth: 0,
      costPerStoreVisitColor: "",
      costPerStoreVisitCount: 0,
      costPerStoreVisitCountFormatted: 0,
      costPerStoreVisitCountPrevMonth: 0,
      costPerStoreVisitCountPrevMonthFormatted: 0,
      costPerStoreVisitDeltaPrevMonth: 0,
      incrementalStoreVisitsColor: "",
      incrementalStoreVisitsCount: 0,
      incrementalStoreVisitsCountFormatted: 0,
      incrementalStoreVisitsCountPrevMonth: 0,
      incrementalStoreVisitsCountPrevMonthFormatted: 0,
      incrementalStoreVisitsDeltaPrevMonth: 0,
      storeVisitsColor: "",
      storeVisitsCount: 0,
      storeVisitsCountFormatted: 0,
      storeVisitsCountPrevMonth: 0,
      storeVisitsCountPrevMonthFormatted: 0,
      storeVisitsDeltaPrevMonth: 0,
      summaryNewData: [],
      SummaryNewTotalData: null,
      summaryNewDealerData: [],
      searchStoreTerm:"",
    };
  },
  created() {
    // Accessing cookies when the component is created
    this.geoEnabled = Cookies.get(useJwt.jwtConfig.gmbGeo);
    this.summaryEnabled = Cookies.get(useJwt.jwtConfig.gmbSummary);
    this.organicEnabled = Cookies.get(useJwt.jwtConfig.gmbOrganic);
    this.paidEnabled = Cookies.get(useJwt.jwtConfig.gmbPaid);
    this.postsEnabled = Cookies.get(useJwt.jwtConfig.gmbPosts);
    this.reviewsEnabled = Cookies.get(useJwt.jwtConfig.gmbReviews);
    this.storesEnabled = Cookies.get(useJwt.jwtConfig.gmbStores);
  },
  watch: {
    IncreaseTrifficCount(newValue) {
      this.chartOptions.series[0].data[0].value = newValue;
    },
    DecreaseTrifficCount(newValue) {
      this.chartOptions.series[0].data[1].value = newValue;
    },
  },
  computed: {
    IndividualDatatableColumns() {
      return [
        // {
        //   label: "Store code",
        //   field: "storeCode",
        //   filterable: true,
        //   thClass: "text-center",
        // },
        {
          label: "Business Name",
          field: "name",
          filterable: true,
          thClass: "text-center",
          tdClass: "text-center",
          sortable: false
        },
        {
          label: "Store Address",
          field: "address",
          filterable: true,
          thClass: "text-center",
          tdClass: "text-center",
          sortable: false
        },
        {
          label: "Overall rating",
          field: "insight.overallRating",
          filterable: true,
          thClass: "text-center",
          tdClass: "text-center",
          sortFn: this.sortStringtoNumFn
        },
        // {
        //   label: "Total searches",
        //   field: "insight.totalSearches",
        //   filterable: true,
        //   thClass: "text-center",
        //   sortFn: this.sortFn
        // },
        // {
        //   label: "Direct searches",
        //   field: "insight.directSearches",
        //   filterable: true,
        //   thClass: "text-center",
        //   sortFn: this.sortFn
        // },
        // {
        //   label: "Discovery searches",
        //   field: "insight.discoverySearches",
        //   filterable: true,
        //   thClass: "text-center",
        //   sortFn: this.sortStringtoNumFn
        // },
        {
          label: "Search views",
          field: "insight.searchViews",
          filterable: true,
          thClass: "text-center",
          tdClass: "text-center",
          sortFn: this.sortFn
        },
        {
          label: "Maps views",
          field: "insight.mapViews",
          filterable: true,
          thClass: "text-center",
          tdClass: "text-center",
          sortFn: this.sortFn
        },
        {
          label: "Total views",
          field: "insight.totalViews",
          filterable: true,
          thClass: "text-center",
          tdClass: "text-center",
          sortFn: this.sortFn
        },
        {
          label: "Website actions",
          field: "insight.websiteActions",
          filterable: true,
          tdClass: "text-center",
          thClass: "text-center",
          sortFn: this.sortFn
        },
        {
          label: "Directions actions",
          field: "insight.directionActions",
          filterable: true,
          thClass: "text-center",
          tdClass: "text-center",
          sortFn: this.sortFn
        },
        {
          label: "Total actions",
          field: "insight.totalActions",
          filterable: true,
          thClass: "text-center",
          sortFn: this.sortFn,
          tdClass: "text-center"
        },
        {
          label: "Calls done",
          field: "insight.callActions",
          tdClass: "text-center",
          filterable: true,
          thClass: "text-center",
          sortFn: this.sortFn
        },
      ];
    },
    StorewiseDatatableColumns() {
      return [
        {
          label: "Dealer Name",
          field: "customer.descriptiveName",
          filterable: true,
          thClass: "text-center",
          tdClass: "text-center",
          sortable: false
        },
        {
          label: "Store Address",
          field: "address",
          filterable: true,
          thClass: "text-center",
          tdClass: "text-center",
          sortable: false
        },
        {
          label: "Overall rating",
          field: "insight.overallRating",
          filterable: true,
          thClass: "text-center",
          tdClass: "text-center",
          sortFn: this.sortStringtoNumFn
        },
        // {
        //   label: "Total searches",
        //   field: "insight.totalSearches",
        //   filterable: true,
        //   thClass: "text-center",
        //   sortFn: this.sortFn
        // },
        // {
        //   label: "Direct searches",
        //   field: "insight.directSearches",
        //   filterable: true,
        //   thClass: "text-center",
        //   sortFn: this.sortFn
        // },
        // {
        //   label: "Discovery searches",
        //   field: "insight.discoverySearches",
        //   filterable: true,
        //   thClass: "text-center",
        //   sortFn: this.sortStringtoNumFn
        // },
        {
          label: "Search views",
          field: "insight.searchViews",
          filterable: true,
          thClass: "text-center",
          tdClass: "text-center",
          sortFn: this.sortFn
        },
        {
          label: "Maps views",
          field: "insight.mapViews",
          filterable: true,
          thClass: "text-center",
          tdClass: "text-center",
          sortFn: this.sortFn
        },
        {
          label: "Total views",
          field: "insight.totalViews",
          filterable: true,
          thClass: "text-center",
          tdClass: "text-center",
          sortFn: this.sortFn
        },
        {
          label: "Website actions",
          field: "insight.websiteActions",
          filterable: true,
          tdClass: "text-center",
          thClass: "text-center",
          sortFn: this.sortFn
        },
        {
          label: "Directions actions",
          field: "insight.directionActions",
          filterable: true,
          thClass: "text-center",
          tdClass: "text-center",
          sortFn: this.sortFn
        },
        {
          label: "Total actions",
          field: "insight.totalActions",
          filterable: true,
          thClass: "text-center",
          sortFn: this.sortFn,
          tdClass: "text-center"
        },
        {
          label: "Calls done",
          field: "insight.callActions",
          tdClass: "text-center",
          filterable: true,
          thClass: "text-center",
          sortFn: this.sortFn
        },
      ];
    },
    secondPartAsPrevNumber() {
      const parts = this.organicToPaidStoreVisitsratioPrevMonth.split(":");
      if (parts.length === 2) {
        return parseInt(parts[1], 10);
      } else {
        return null;
      }
    },
    secondPartAsCurrentNumber() {
      const parts = this.organicToPaidStoreVisitsRatio.split(":");
      if (parts.length === 2) {
        return parseInt(parts[1], 10);
      } else {
        return null;
      }
    },
    totalIncrease() {
      return this.IncreaseTriffic.length;
    },
    displayedIncreaseItems() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.IncreaseTriffic.slice(start, end);
    },
    totalDecrease() {
      return this.DecreaseTriffic.length;
    },
    displayedDecreaseItems() {
      const start = (this.currentDecreasePage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.DecreaseTriffic.slice(start, end);
    },
  },
  methods: {
    fetchGMBCustomers() {
      this.clientidInitallY = {}
      const slug = this.$route.params.slug;
      if(!slug) return;
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/api/client/${slug}`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          this.clientidInitallY = response.data.client
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
    cropText(text, maxLength) {
      if (!text) {
        return "";
      }
      const words = text.split(" ");
      if (words.length > maxLength) {
        const croppedWords = words.slice(0, maxLength);
        return `${croppedWords.join(" ")}...`;
      } else {
        return text;
      }
    },
    exportIndividiualStoreData() {
      const csvContent =
        "data:text/csv;charset=utf-8," +
        this.convertIndividualStoreData(this.storeWiseGMBData);
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "Individual_store_data.csv");
      document.body.appendChild(link);
      link.click();
    },
    convertIndividualStoreData(data) {
      const headers = this.IndividualDatatableColumns.map(
        (column) => column.label
      );
      const rows = data.map((row) =>
        this.IndividualDatatableColumns.map((column) => {
          const value = this.extractValue(row, column.field);
          if (column.field === "address") {
            return value.replace(/,/g, "`");
          }
          if (column.field === "name") {
            return value.replace(/,/g, "`");
          }
          return value;
        })
      );
      const csvContent = [headers.join(","), ...rows].join("\n");
      return csvContent;
    },
    extractValue(obj, field) {
      const fieldSegments = field.split(".");
      let value = obj;
      for (const segment of fieldSegments) {
        value = value[segment];
        if (value === undefined) {
          return "";
        }
      }
      return value;
    },
    formatChartsDate(inputDate) {
      const dateParts = inputDate.split("-");
      const day = dateParts[2];
      const monthAbbreviation = this.getMonthAbbreviation(
        parseInt(dateParts[1], 10)
      );
      const year = dateParts[0].slice(-2); // Take the last two digits of the year

      const formattedDate = `${day} ${monthAbbreviation}'${year}`;
      return formattedDate;
    },
    getMonthAbbreviation(month) {
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      return months[month - 1];
    },
    getReportData1(startDate, endDate) {
      this.startDate1 = startDate;
      this.endDate1 = endDate;
    },
    getReportData2(startDate, endDate) {
      this.startDate2 = startDate;
      this.endDate2 = endDate;
      if (this.activeTab === "Summary") {
        this.getGBMData();
      }
      if (this.activeTab === "Organic") {
        this.getGBMOrganicData();
        this.getStorewiseData();
      }
      if (this.activeTab === "Paid") {
        this.getGMBPaidSummaryData();
        this.getGMBpaidDetailData();
        // this.getStoreWisePaidData();
      }
    },
    isTableItemClicked() {
      this.iClickedItem = true;
    },
    updateChartData() {
      const monthNames = [
        "December",
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
      ];
      const selectedMonth = this.selectedMonth;
      const currentMonth = monthNames[selectedMonth];
      const nextMonth = monthNames[(selectedMonth + 1) % 12];

      const orgToPaidChart = {
        chart: {
          height: 215,
          // type: "line",
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val;
          },
          offsetY: -15,
          style: {
            fontSize: "9px",
            colors: ["#8358BE"],
          },
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [this.gmbData.label1, this.gmbData.label2],
          labels: {
            style: {
              fontSize: "10px",
              color: "#333",
              fontFamily: "Arial, sans-serif",
            },
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return "" + val + "";
            },
          },
        },
      };

      const commonOptions = {
        chart: {
          height: 195,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true,
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val;
          },
          offsetY: -15,
          style: {
            fontSize: "9px",
            colors: ["#8358BE"],
          },
        },
        xaxis: {
          categories: [this.gmbData.label1, this.gmbData.label2],
          labels: {
            style: {
              fontSize: "10px",
              color: "#333",
              fontFamily: "Arial, sans-serif",
            },
          },
        },
      };
      const TopOptions = {
        chart: {
          height: 195,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true,
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val;
          },
          offsetY: -15,
          style: {
            fontSize: "9px",
            colors: ["#8358BE"],
          },
        },
        xaxis: {
          categories: [this.gmbData.label2],
          labels: {
            style: {
              fontSize: "10px",
              color: "#333",
              fontFamily: "Arial, sans-serif",
            },
          },
        },
      };
      this.options = { ...commonOptions };
      this.optionsSummaryNew = { ...TopOptions };
      this.chartOragnicOptions = { ...orgToPaidChart };
      this.seriesDrivingData = [
        {
          name: "Driving Directions",
          data: [this.drivingDirectionsPrevData, this.drivingDirectionsData],
        },
      ];

      this.seriesPerData = [
        {
          name: "Cost Per Store Visit",
          data: [this.costPerStoreVisitPrevData, this.costPerStoreVisitData],
        },
      ];
      this.seriesSummaryNewCPIIncremental = [
        {
          name: "Incremental SV",
          data: [this.incrementalStoreVisitsCount],
        },
      ];
      this.seriesSummaryNewCPICPISV = [
        {
          name: "CPISV",
          data: [this.costPerIncrementalStoreVisitCount],
        },
      ];
      this.seriesOraganicData = [
        {
          name: "Organic",
          data: [
            this.organicToPaidStoreVisitsratioPrevMonth,
            this.organicToPaidStoreVisitsRatio,
          ],
        },
        {
          name: "Paid",
          data: [this.secondPartAsPrevNumber, this.secondPartAsCurrentNumber],
        },
      ];

      this.seriesCostDoneData = [
        {
          name: "Calls Done",
          data: [this.costDonePrevData, this.costDoneData],
        },
      ];
    },
    updateSummayNewChartData() {
      (this.seriesSummaryNewCPI = [
        {
          name: "Paid SV",
          data: [this.storeVisitsCountPrevMonth, this.storeVisitsCount],
        },
      ]),
        (this.seriesSummaryNewCPIIncremental = [
          {
            name: "Incremental SV",
            data: [
              this.costPerIncrementalStoreVisitCountPrevMonth,
              this.costPerIncrementalStoreVisitCount,
            ],
          },
        ]),
        (this.seriesSummaryNewCPSV = [
          {
            name: "CPSV",
            data: [
              this.costPerStoreVisitCountPrevMonth,
              this.costPerStoreVisitCount,
            ],
          },
        ]),
        (this.seriesSummaryNewCPICPISV = [
          {
            name: "CPISV",
            data: [
              this.incrementalStoreVisitsCountPrevMonth,
              this.incrementalStoreVisitsCount,
            ],
          },
        ]);
    },
    updateChartOrgData() {
      const monthNames = [
        "December",
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
      ];

      const currentMonth = monthNames[this.selectedMonth];
      const nextMonth = monthNames[(this.selectedMonth + 1) % 12];

      this.seriesViews = [
        {
          name: this.organicLabel1,
          data: [
            this.orgTotalViewsPrev,
            this.orgMapViewsPrev,
            this.orgSearchViewsPrev,
          ],
        },
        {
          name: this.organicLabel2,
          data: [this.orgTotalViews, this.orgMapViews, this.orgSearchViews],
        },
      ];

      this.seriesActions = [
        {
          name: this.organicLabel1,
          data: [
            this.orgTotalActionsPrev,
            this.orgCallActionsPrev,
            this.orgWebsiteActionsPrev,
          ],
        },
        {
          name: this.organicLabel2,
          data: [
            this.orgTotalActions,
            this.orgCallActions,
            this.orgWebsiteActions,
          ],
        },
      ];

      this.optionsOrganic = {
        chart: {
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true,
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: (val) => val,
          offsetY: -15,
          style: {
            fontSize: "9px",
            colors: ["#8358BE"],
          },
        },
        xaxis: {
          categories: [this.organicLabel1, this.organicLabel2],
          labels: {
            style: {
              fontSize: "10px",
              color: "#333",
              fontFamily: "Arial, sans-serif",
            },
          },
        },
      };
      this.seriesRatingData = [
        { name: "Ratings", data: [this.prevRatings, this.ratings] },
      ];
      this.seriesShopData = [
        { name: "Shop", data: [this.orgStoresPrev, this.orgStores] },
      ];
      // ...
    },
    updateChartPaidSummayData() {
      const monthNames = [
        "December",
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
      ];

      const currentMonth = monthNames[this.selectedMonth];
      const nextMonth = monthNames[(this.selectedMonth + 1) % 12];

      this.seriesPaidSummaryStorePerData = [
        {
          name: "total store visits",
          data: [
            this.paidSummayTotalStoreVisitCountPrev,
            this.paidSummayTotalStoreVisitCount,
          ],
        },
      ];
      this.seriesPaidSummaryCostData = [
        {
          name: "cost per store visit",
          data: [this.paidSummayCostPerPrev, this.paidSummayCostPerCount],
        },
      ];
      this.seriesPaidSummaryTotalSpendsData = [
        {
          name: "total spends",
          data: [
            this.paidSummayTotalSpendsCountPrev,
            this.paidSummayTotalSpendsCount,
          ],
        },
      ];
      this.optionsPaidSummary = {
        chart: {
          width: 360,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true,
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: (val) => val,
          offsetY: -15,
          style: {
            fontSize: "9px",
            colors: ["#8358BE"],
          },
        },
        xaxis: {
          categories: [this.summaryPaidLabel1, this.summaryPaidLabel2],
          labels: {
            style: {
              fontSize: "10px",
              color: "#333",
              fontFamily: "Arial, sans-serif",
            },
          },
        },
      };
    },
    setIntialClient() {
      if (!this.$route.params.slug) {
        this.selectedClient = this.fetchClientsList[0];
        this.getGBMData();
      }
      if(this.$route.params.slug){
        let selectedOrganicClient = this.clientidInitallY;
        let matchingClient = this.fetchClientsList.find(client => client._id === selectedOrganicClient._id);
        if (matchingClient) {
          this.selectedClient = matchingClient;
          this.getGBMData();
        } else {
          console.log('Client not found in fetchClients array');
        }
      }
    },
    setIntialOrganicClient() {
      if (!this.$route.params.slug) {
        this.selectedOrganicClient = this.fetchClientsList[0]._id;
        this.getGBMOrganicData();
        this.getStorewiseData();
      }else{
        let clientIDIntiall = this.clientidInitallY._id;
        this.selectedOrganicClient = clientIDIntiall;
        this.getGBMOrganicData();
        this.getStorewiseData();
      }
    },
    setIntialNewOrganicClient() {
      if (!this.$route.params.slug) {
        this.selectedClientSummary = this.fetchClientsList[0]._id;
        this.getGBMOrganicData();
        this.getStorewiseData();
      }else{
        let clientIDIntiall = this.clientidInitallY._id;
        // this.fetchClientsList[0]._id = this.clientidInitallY._id;
        this.selectedClientSummary = clientIDIntiall;
        this.getGBMOrganicData();
        this.getStorewiseData();
      }
    },
    getAllGMBSummaryData() {
      this.getGBMData();
    },
    getAllGMBPaidSummaryData() {
      this.getGMBPaidSummaryData();
      this.getGMBpaidDetailData();
      // this.getStoreWisePaidData();
    },
    getAllGMBOrganicData() {
      this.getGBMOrganicData();
      this.getStorewiseData();
    },
    getAllGMBStoreLevelSummaryDataChange() {
      this.getAllGMBStoreLevelSummaryData();
    },
    getSummaryNewData() {
      this.getBrandSummary();
      this.getBrandSummaryTableData();
      this.getDealerSummaryTableData();
    },
    fetchClients() {
      this.isDataLoading = true;
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/client/get`,
          {
            limit: 50,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
              "Content-type": "application/json",
            },
          }
        )
        .then((response) => {
          this.fetchClientsList = response.data.clients.filter((client) => {
            return client.gmb && client.gmb.accountID.length;
          });

          this.setIntialClient();
          // this.setIntialOrganicClient();
          // this.setIntialNewOrganicClient()
          // this.getGMBPaidSummaryData();
          // this.getGMBpaidDetailData();
          // this.getAllGMBStoreLevelSummaryData();
        })
        .catch((error) => {
          console.log(error);
          this.isDataLoading = false;
        });
    },
    isMonthSummayDisabled(month) {
      if (this.selectedYear == '2024') {
        const currentMonth = new Date().getMonth();
        const monthIndex = this.months.indexOf(month);
        return monthIndex >= currentMonth + 1;
      }

    },
    isMonthOrgDisabled(month) {
      if (this.selectedYear == '2024') {
        const currentMonth = new Date().getMonth();
        const monthIndex = this.monthsOrganic.indexOf(month);
        return monthIndex >= currentMonth + 1;
      }
    },
    isMonthSummayPaidDisabled(month) {
      if (this.selectedYear == '2024') {
        const currentMonth = new Date().getMonth();
        const monthIndex = this.monthsOrganicSummaryPaid.indexOf(month);
        return monthIndex >= currentMonth + 1;
      }
    },
    isMonthSummayStoreLevelDisabled(month) {
      if (this.selectedYear == '2024') {
        const currentMonth = new Date().getMonth();
        const monthIndex = this.monthsOrganicSummaryStoreLevel.indexOf(month);
        return monthIndex >= currentMonth + 1;
      }
    },
    getGBMData() {
      let currentDate = new Date();
      if (this.selectedYear == currentDate.getFullYear() && this.selectedMonth > currentDate.getMonth()) {
        this.selectedMonth = currentDate.getMonth();
      }
      this.isDataLoading = true;
      this.gmbData = [];
      this.incrementalStoreVisitsCount  = 0
      this.costPerIncrementalStoreVisitCount = 0
      let s1 = this.startDate1;
      let e1 = this.endDate1;
      let s2 = this.startDate2;
      let e2 = this.endDate2;
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/gmb/summary`,
          {
            clientID: this.selectedClient._id,
            month: this.selectedMonth,
            year: parseInt(this.selectedYear, 10),
            summaryType: this.summaryTabFilterType,
            startDate1: s1,
            endDate1: e1,
            startDate2: s2,
            endDate2: e2,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
              "Content-type": "application/json",
            },
          }
        )
        .then((response) => {
          this.gmbData = response.data;
          this.incrementalStoreVisitsCount = this.gmbData.incrementalStoreVisits.count;
          this.costPerIncrementalStoreVisitCount = this.gmbData.costPerIncrementalStoreVisit.count;
          this.costPerStoreVisitData = this.gmbData.costPerStoreVisit.count;
          this.costPerStoreVisitPrevData = this.gmbData.costPerStoreVisit.countPrevMonth;
          this.costPerStoreVisitDeltaData = this.gmbData.costPerStoreVisit.deltaPrevMonth;
          this.costPerStoreVisitDeltaColor = this.gmbData.costPerStoreVisit.color;
          this.drivingDirectionsDeltaData = this.gmbData.drivingDirections.deltaPrevMonth;
          this.drivingDirectionsData = this.gmbData.drivingDirections.count;
          this.drivingDirectionsPrevData = this.gmbData.drivingDirections.countPrevMonth;
          this.drivingDirectionsDeltaColor = this.gmbData.drivingDirections.color;
          this.costDoneData = this.gmbData.callsDone.count;
          this.costDonePrevData = this.gmbData.callsDone.countPrevMonth;
          this.costDoneDeltaData = this.gmbData.callsDone.deltaPrevMonth;
          this.costDoneDeltaDataColor = this.gmbData.callsDone.color;
          this.organicToPaidStoreVisitsRatio = this.gmbData.organicToPaidStoreVisits.ratio;
          this.organicToPaidStoreVisitsratioPrevMonth = this.gmbData.organicToPaidStoreVisits.ratioPrevMonth;
          this.costEfficientStoreCode = this.gmbData.costEfficientStore.storeCode;
          this.costEfficientStoreName = this.gmbData.costEfficientStore.storeName;
          this.costEfficientStoreAddress = this.gmbData.costEfficientStore.storeAddress;
          this.costEfficientStoreVisit = this.gmbData.costEfficientStore.storeVisits;
          this.costEfficientStoreTitle = this.gmbData.costEfficientStore.title;
          this.IncreaseTriffic = this.gmbData.increasedTrafficStores.stores;
          this.IncreaseTrifficCount = this.gmbData.increasedTrafficStores.count;
          this.DecreaseTriffic = this.gmbData.decreasedTrafficStores.stores;
          this.DecreaseTrifficCount = this.gmbData.decreasedTrafficStores.count;
          this.updateChartData();
          this.isDataLoading = false;
        })
        .catch((error) => {
          this.incrementalStoreVisitsCount  = 0
          this.costPerIncrementalStoreVisitCount = 0
          this.costPerStoreVisitData = 0;
          this.costPerStoreVisitPrevData = 0;
          this.costPerStoreVisitDeltaData = 0;
          this.drivingDirectionsData = 0;
          this.drivingDirectionsPrevData = 0;
          this.drivingDirectionsDeltaData = 0;
          this.costDoneData = 0;
          this.costDonePrevData = 0;
          this.costDoneDeltaData = 0;
          this.organicToPaidStoreVisitsRatio = 0;
          this.organicToPaidStoreVisitsratioPrevMonth = "";
          this.costEfficientStoreCode = "";
          this.costEfficientStoreVisit = 0;
          this.costEfficientStoreTitle = "";
          this.IncreaseTriffic = [];
          this.IncreaseTrifficCount = 0;
          this.DecreaseTriffic = [];
          this.DecreaseTrifficCount = 0;
          this.seriesDrivingData = [
            {
              name: "Driving Directions",
              data: [0, 0],
            },
          ];
          this.seriesPerData = [
            {
              name: "Cost Per Store Visit",
              data: [0, 0],
            },
          ];
          this.seriesCostDoneData = [
            {
              name: "Calls Done",
              data: [0, 0],
            },
          ];
          this.seriesOraganicData = [
            {
              name: "Organic",
              data: [0, 0],
            },
            {
              name: "Paid",
              data: [0, 0],
            },
          ];
          this.seriesSummaryNewCPIIncremental = [
        {
          name: "Incremental SV",
          data: [0],
        },
      ];
      this.seriesSummaryNewCPICPISV = [
        {
          name: "CPISV",
          data: [0],
        },
      ];
      this.optionsSummaryNew = { chart: {
          height: 195,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        } };
      this.chartOragnicOptions = { chart: {
          height: 195,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        } };
        this.options = { chart: {
          height: 195,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        } };
          console.log(error);
          this.isDataLoading = false;
        });
    },
    getGBMOrganicData() {
      let currentDate = new Date();
      if (this.selectedYear == currentDate.getFullYear() && this.selectedMonth > currentDate.getMonth()) {
        this.selectedMonth = currentDate.getMonth();
      }

      this.isOrganicDataLoading = true;
      this.orgDirectionActions = 0;
      this.orgDirectionActionsPrev = 0;
      this.orgDirectionActionsPer = 0;
      this.orgTotalSearches = 0;
      this.orgTotalSearchesPrev = 0;
      this.orgTotalSearchesPer = 0;
      this.orgDirectSearch = 0;
      this.orgDirectSearchPrev = 0;
      this.orgDirectSearchPer = 0;
      this.orgDiscSearches = 0;
      this.orgDiscSearchesPrev = 0;
      this.orgDiscSearchesPer = 0;;
      this.orgMapViews = 0;
      this.orgMapViewsPrev = 0;
      this.orgMapViewsPer = 0;
      this.orgWebsiteActions = 0;
      this.orgWebsiteActionsPrev = 0;
      this.orgWebsiteActionsPer = 0;
      this.orgCallActions = 0;
      this.orgCallActionsPrev = 0;
      this.orgActionPercentage = 0;
      this.orgTotalViews = 0;
      this.orgTotalViewsPrev = 0;
      this.orgTotalViewsPer = 0;
      this.orgSearchViews = 0;
      this.orgSearchViewsPrev = 0;
      this.orgSearchViewsPer = 0;
      this.orgTotalActions = 0;
      this.orgTotalActionsPrev = 0;
      this.orgTotalActionsPer = 0;
      this.ratings = 0;
      this.prevRatings = 0;
      this.ratingsPer = 0;
      this.orgStores = 0;
      this.orgStoresPrev = 0;
      this.orgStoresPer = 0;
      let s1 = this.startDate1;
      let e1 = this.endDate1;
      let s2 = this.startDate2;
      let e2 = this.endDate2;
      this.updateChartOrgData();
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/gmb/organic`,
          {
            clientID: this.selectedClient._id,
            month: this.selectedMonth,
            year: parseInt(this.selectedYear, 10),
            startDate1: s1,
            endDate1: e1,
            startDate2: s2,
            endDate2: e2,
            summaryType: this.summaryTabFilterType,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
              "Content-type": "application/json",
            },
          }
        )
        .then((response) => {
          this.organicData = response;
          this.organicLabel1 = response.data.label1;
          this.organicLabel2 = response.data.label2;
          this.orgDirectionActions = response.data.fields[0].value;
          this.orgDirectionActionsPrev = response.data.fields[0].prevValue;
          this.orgDirectionActionsPer =
            response.data.fields[0].percentageChange;
          this.orgTotalSearches = response.data.fields[1].value;
          this.orgTotalSearchesPrev = response.data.fields[1].prevValue;
          this.orgTotalSearchesPer = response.data.fields[1].percentageChange;
          this.orgDirectSearch = response.data.fields[2].value;
          this.orgDirectSearchPrev = response.data.fields[2].prevValue;
          this.orgDirectSearchPer = response.data.fields[2].percentageChange;
          this.orgDiscSearches = response.data.fields[3].value;
          this.orgDiscSearchesPrev = response.data.fields[3].prevValue;
          this.orgDiscSearchesPer = response.data.fields[3].percentageChange;
          this.orgMapViews = response.data.fields[4].value;
          this.orgMapViewsPrev = response.data.fields[4].prevValue;
          this.orgMapViewsPer = response.data.fields[4].percentageChange;
          this.orgWebsiteActions = response.data.fields[5].value;
          this.orgWebsiteActionsPrev = response.data.fields[5].prevValue;
          this.orgWebsiteActionsPer = response.data.fields[5].percentageChange;
          this.orgCallActions = response.data.fields[6].value;
          this.orgCallActionsPrev = response.data.fields[6].prevValue;
          this.orgActionPercentage = response.data.fields[6].percentageChange;
          this.orgTotalViews = response.data.fields[7].value;
          this.orgTotalViewsPrev = response.data.fields[7].prevValue;
          this.orgTotalViewsPer = response.data.fields[7].percentageChange;
          this.orgSearchViews = response.data.fields[8].value;
          this.orgSearchViewsPrev = response.data.fields[8].prevValue;
          this.orgSearchViewsPer = response.data.fields[8].percentageChange;
          this.orgTotalActions = response.data.fields[9].value;
          this.orgTotalActionsPrev = response.data.fields[9].prevValue;
          this.orgTotalActionsPer = response.data.fields[9].percentageChange;
          this.ratings = response.data.fields[10].value;
          this.prevRatings = response.data.fields[10].prevValue;
          this.ratingsPer = response.data.fields[10].percentageChange;
          this.orgStores = response.data.fields[11].value;
          this.orgStoresPrev = response.data.fields[11].prevValue;
          this.orgStoresPer = response.data.fields[11].percentageChange;
          this.updateChartOrgData();
          this.isOrganicDataLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.seriesRatingData = [{ name: "Ratings", data: [0, 0] }];
          this.seriesShopData = [{ name: "Ratings", data: [0, 0] }];
          this.isOrganicDataLoading = false;
        });
    },
    getStorewiseData(){
      this.isStoreDataLoadProperly =  true
      let currentDate = new Date();
      if (this.selectedYear == currentDate.getFullYear() && this.selectedMonth > currentDate.getMonth()) {
        this.selectedMonth = currentDate.getMonth();
      }
      let s1 = this.startDate1;
      let e2 = this.endDate2;
      this.storeWiseGMBData = []
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/gmb/insights/all`,
          {
            clientID: this.selectedClient._id,
            month: this.selectedMonth,
            year: parseInt(this.selectedYear, 10),
            startDate: s1,
            endDate: e2,
            allAccounts: true
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
              "Content-type": "application/json",
            },
          }
        )
        .then((response) => { 
          this.storeWiseGMBData = response.data
          this.isStoreDataLoadProperly =  false
        })
        .catch((error) => {
          this.isStoreDataLoadProperly =  false
          console.log(error);
        });
    },
    getGMBPaidSummaryData() {

      let currentDate = new Date();
      if (this.selectedYear == currentDate.getFullYear() && this.selectedMonth > currentDate.getMonth()) {
        this.selectedMonth = currentDate.getMonth();
      }

      this.isSummaryPaidLoading = true;
      this.paidSummayCostPerCount = 0;
      this.paidSummayTotalSpendsCount = 0;
      this.paidSummayTotalStoreVisitCount = 0;
      this.paidSummayCostPerPrev = 0;
      this.paidSummayTotalSpendsCountPrev = 0;
      this.paidSummayTotalStoreVisitCountPrev = 0;
      this.paidSummayCostPerPer = 0;
      this.paidSummayTotalSpendsCountPer = 0;
      this.paidSummayTotalStoreVisitCountPer = 0;
      this.paidSummmaryCostVisitColor = "";
      this.paidSummmaryTotalSpendsColor = "";
      this.paidSummmaryTotalStoreVisitColor = "";
      this.paidSummayCostPerCountFormated = "";
      this.paidSummayTotalSpendsCountFormated = "";
      let s1 = this.startDate1;
      let e1 = this.endDate1;
      let s2 = this.startDate2;
      let e2 = this.endDate2;

      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/gmb/paid/summary?month=${this.selectedMonth}&year=${this.selectedYear}&clientID=${this.selectedClient._id}&summaryType=${this.summaryTabFilterType}&startDate2=${s2}&endDate2=${e2}&startDate1=${s1}&endDate1=${e1}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.paidSummaryData = response.data;
          this.summaryPaidLabel1 = this.paidSummaryData.label1;
          this.summaryPaidLabel2 = this.paidSummaryData.label2;
          this.paidSummayCostPerCount = this.paidSummaryData.costPerStoreVisit.count;
          this.paidSummayTotalSpendsCount = this.paidSummaryData.totalSpends.count;
          this.paidSummayTotalStoreVisitCount = this.paidSummaryData.totalStoreVisits.count;
          this.paidSummayCostPerPrev = this.paidSummaryData.costPerStoreVisit.countPrevMonth;
          this.paidSummayTotalSpendsCountPrev = this.paidSummaryData.totalSpends.countPrevMonth;
          this.paidSummayTotalStoreVisitCountPrev = this.paidSummaryData.totalStoreVisits.countPrevMonth;
          this.paidSummayCostPerPer = this.paidSummaryData.costPerStoreVisit.deltaPrevMonth;
          this.paidSummayTotalSpendsCountPer = this.paidSummaryData.totalSpends.deltaPrevMonth;
          this.paidSummayTotalStoreVisitCountPer = this.paidSummaryData.totalStoreVisits.deltaPrevMonth;
          this.paidSummmaryCostVisitColor = this.paidSummaryData.costPerStoreVisit.color;
          this.paidSummmaryTotalSpendsColor = this.paidSummaryData.totalSpends.color;
          this.paidSummmaryTotalStoreVisitColor = this.paidSummaryData.totalStoreVisits.color;
          (this.paidSummayCostPerCountFormated = this.paidSummaryData.costPerStoreVisit.countFormatted),
            (this.paidSummayTotalSpendsCountFormated = this.paidSummaryData.totalSpends.countFormatted);
          this.updateChartPaidSummayData();
          this.isSummaryPaidLoading = false;
        })
        .catch((error) => {
          this.seriesPaidSummaryStorePerData = [
        {
          name: "Total Store Visits",
          data: [0, 0],
        },
      ],
      this.seriesPaidSummaryTotalSpendsData = [
        {
          name: "Cost Per Store Visit",
          data: [0, 0],
        },
      ],
      this.seriesPaidSummaryCostData = [
        {
          name: "Total Spends",
          data: [0, 0],
        },
      ],
          console.log(error);
          this.isSummaryPaidLoading = false;
        });
    },
    getGMBpaidDetailData() {
      this.paidTableData = [];
      this.isSummaryPaidDetailsLoading = true;
      let s2 = this.startDate2;
      let e2 = this.endDate2;
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/gmb/paid/detailed?month=${this.selectedMonth}&year=${this.selectedYear}&clientID=${this.selectedClient._id}&summaryType=${this.summaryTabFilterType}&startDate=${s2}&endDate=${e2}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          let originalData = response.data;
          for (const date in originalData) {
            if (originalData.hasOwnProperty(date)) {
              const item = originalData[date];
              this.paidTableData.push({
                date,
                ...item,
              });
            }
          }
          this.isSummaryPaidDetailsLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isSummaryPaidDetailsLoading = false;
        });
    },
    getStoreWisePaidData(){
      this.isLoadingPaidStorewiseData = true
      this.PaidStorewiseData = []
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/gmb/insights/storeWiseData?clientID=${this.selectedClient._id}&summaryType=${this.summaryTabFilterType}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        ).then((response) => {
          this.PaidStorewiseData = response.data;
          this.isLoadingPaidStorewiseData = false
        })
        .catch((error) => {
         this.isLoadingPaidStorewiseData = false
          console.log(error);
        });
    },
    getAllGMBStoreLevelSummaryData() {
      this.isSummaryStoreLevelLoading = true;
      this.StoreLevelIncrease = [];
      this.StoreLevelDecrease = [];
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/gmb/analytics/storevisits?month=${this.selectedMonth}&clientID=${this.selectedClient._id}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.StoreLevelIncrease = response.data.increasedTrafficStores;
          this.StoreLevelDecrease = response.data.decreasedTrafficStores;
          this.isSummaryStoreLevelLoading = false;
        })
        .catch((error) => {
          this.isSummaryStoreLevelLoading = false;
          console.log(error);
        });
    },
    changeTab(tabName) {
      this.activeTab = tabName;
      if (this.activeTab === "Organic") {
        this.summaryTabFilterType = "monthRange";
        this.getAllGMBOrganicData();
      } else if (this.activeTab === "Summary") {
        this.summaryTabFilterType = "monthRange";
        this.getAllGMBSummaryData();
      } else if (this.activeTab === "Paid") {
        this.summaryTabFilterType = "monthRange";
        this.getGMBPaidSummaryData();
        this.getGMBpaidDetailData();
        // this.getStoreWisePaidData();
      } else if (this.activeTab === "StoreLevel") {
        this.getAllGMBStoreLevelSummaryData();
      }
    },
  },
  mounted() {
    this.fetchGMBCustomers();
    this.fetchClients();
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "../../assets/scss/gmb-dashboard.scss"
</style>
