<template>
  <div>
    <b-card
      style="margin-left: -7px; margin-right: -7px; min-height: 70vh; background-color: #F5F7F8 !important;"
    >
      <b-row>
        <b-col cols="12">
          <b-card>
            <b-row>
              <b-col cols="3">
                <div>
                  <div style="display: flex; gap: 0.8rem;">
                    <label style="margin-top: 5px;">Clients:</label>
                    <select
                      v-model="selectedGeoAnalyticsClient"
                      class="gmb-review-select "
                      @change="getGeoAnalyticsData"
                    >
                      <option value="">Select Client</option>
                      <option
                        v-for="onetype in fetchClientsList"
                        :value="onetype._id"
                        class="form-control "
                        id="lg_type"
                        name="lg_type"
                        v-bind:key="onetype._id"
                      >
                        {{ onetype.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </b-col>
              <b-col cols="3">
                <div>
                  <div style="display: flex; gap: 0.8rem;">
                    <label style="margin-top: 5px;">Locations:</label>
                    <select
                      v-model="selectedGeoAnalyticsClient"
                      class="gmb-review-select "
                      @change="getGeoAnalyticsData"
                    >
                      <option value="">Select Client</option>
                      <option
                        v-for="onetype in fetchClientsList"
                        :value="onetype._id"
                        class="form-control "
                        id="lg_type"
                        name="lg_type"
                        v-bind:key="onetype._id"
                      >
                        {{ onetype.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6"><b-card style="height: 68vh;"> </b-card></b-col>
        <b-col cols="6"
          ><b-card style="height: 68vh;">
            <div style="float: right;">
              <div>
                <b-tabs class="borderless-tabs">
                  <b-tab>
                    <template #title>
                      <span style="color: #7752FE;">
                        <b-icon icon="circle-fill" /> CPSV
                      </span>
                    </template>
                  </b-tab>
                  <b-tab>
                    <template #title>
                      <span style="color: #711DB0;">
                        <b-icon icon="circle-fill" /> Driving Directions
                      </span>
                    </template>
                  </b-tab>
                  <b-tab>
                    <template #title>
                      <span style="color: #52D3D8;">
                        <b-icon icon="circle-fill" /> Calls Done
                      </span>
                    </template>
                  </b-tab>
                  <b-tab>
                    <template #title>
                      <span style="color: #65B741;">
                        <b-icon icon="circle-fill" /> Total Views
                      </span>
                    </template>
                  </b-tab>
                </b-tabs>
                <div style="float: right;">
                  <span style="font-size: 12px; color: #b0b0b0;"
                    ><i
                      ><strong>Updated:</strong> 06/01/2024 - 10:20am
                    </i></span
                  >
                </div>
              </div>
              <div>
                <div>
                  <highcharts :options="chartOptions"></highcharts>
                </div>
              </div>
            </div> </b-card
        ></b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { BRow, BCol, BCard, BTab, BTabs } from "bootstrap-vue";
import axios from "axios";
import { getUserToken } from "@/auth/utils";
export default {
  name: "gmb",
  components: {
    BRow,
    BCol,
    BCard,
    BTab,
    BTabs,
  },
  data() {
    return {
      selectedGeoAnalyticsClient: "",
      fetchClientsList: [],
      isReviewClientFetched: false,
    };
  },

  methods: {
    fetchClients() {
      this.isReviewClientFetched = true;
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/client/get`,
          {
            limit: 100,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
              "Content-type": "application/json",
            },
          }
        )
        .then((response) => {
          this.fetchClientsList = response.data.clients.filter((client) => {
            return client.gmb && client.gmb.accountID.length;
          });
          this.setIntialClient();
          this.isReviewClientFetched = false;
        })
        .catch((error) => {
          console.log(error);
          this.isReviewClientFetched = false;
        });
    },
    getGeoAnalyticsData() {},
    setIntialClient() {
      if (!this.$route.params.slug) {
        this.selectedGeoAnalyticsClient = this.fetchClientsList[0]._id;
      }
    },
  },
  mounted() {
    this.fetchClients();
  },
};
</script>

<style></style>
